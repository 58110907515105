import React, { useState, useEffect } from 'react';
import { 
    Alert,
    Box,
    Button, 
    Container, 
    CssBaseline, 
    Paper, 
    Snackbar, 
    TextField, 
    Toolbar, 
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Pagination,
    Divider,
    IconButton,
    Tooltip,
    CircularProgress,
    Link,
    Stepper,
    Step,
    StepLabel,
    FormControlLabel,
    Switch
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import YouTubeIcon from '@mui/icons-material/YouTube';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import UndoIcon from '@mui/icons-material/Undo';
import { BASE_URL } from '../../Constants.js'; // Adjust the import path as needed
import { getToken, getUserId } from '../../auth/auth.js'; // Adjust the import path as needed
import { theme } from '../../theme.js';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
console.log("BASE_URL",BASE_URL);

function LMSAddVideos() {
    const [title, setTitle] = useState('');
    const [url, setUrl] = useState('');
    const [transcript, setTranscript] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [courses, setCourses] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [videos, setVideos] = useState([]);
    const [page, setPage] = useState(1);
    const [videosPerPage] = useState(5);
    const [expandedCourse, setExpandedCourse] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [videoType, setVideoType] = useState('youtube'); // 'youtube' or 'mp4'
    const [videoFile, setVideoFile] = useState(null);
    const [supportingDocs, setSupportingDocs] = useState([]);
    const [newSupportingDocs, setNewSupportingDocs] = useState([]);
    const [docsToKeep, setDocsToKeep] = useState([]);
    const [user, setUser] = useState(null);
    const [activeStep, setActiveStep] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [sendEmailNotification, setSendEmailNotification] = useState(false);

    const fetchCourses = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/courses`, {
                headers: {
                    'Authorization': `Bearer ${getToken()}`
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch courses');
            }
            const data = await response.json();
            setCourses(data);
        } catch (error) {
            console.error('Error fetching courses:', error);
        }
    };

    useEffect(() => {
        fetchCourses();
        setUser({ user_id: getUserId() });
    }, []);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && (file.type === 'application/pdf' || file.type === 'text/plain')) {
            setTranscript(file);
        } else {
            setError('Please upload a PDF or TXT file');
            event.target.value = null;
        }
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        resetForm();
    };

    const handleSupportingDocDelete = (docId) => {
        setDocsToKeep(prev => {
            const existingDoc = prev.find(doc => doc.id === docId);
            if (existingDoc) {
                return prev.map(doc => 
                    doc.id === docId ? { ...doc, keep: !doc.keep } : doc
                );
            } else {
                return [...prev, { id: docId, keep: false }];
            }
        });
    };

    const handleNewSupportingDocDelete = (index) => {
        setNewSupportingDocs(prev => prev.filter((_, i) => i !== index));
    };

    const handleSupportingDocsChange = (event) => {
        const files = Array.from(event.target.files);
        setNewSupportingDocs(prev => [...prev, ...files]);
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const isStepOneValid = () => {
        return selectedCourse && title;
    };

    const isStepTwoValid = () => {
        return (videoType === 'youtube' && url) || 
               (videoType === 'mp4' && videoFile) || 
               (transcript && !editMode);
    };

    const renderStepContent = () => {
        switch (activeStep) {
            case 0:
                return (
                    <>
                        <FormControl fullWidth margin="normal" required>
                            <InputLabel id="associated-course-label">Associated Course</InputLabel>
                            <Select
                                labelId="associated-course-label"
                                id="associatedCourse"
                                value={selectedCourse}
                                onChange={(e) => setSelectedCourse(e.target.value)}
                                label="Associated Course"
                                required
                            >
                                <MenuItem value="">
                                    <em>Select a course</em>
                                </MenuItem>
                                {courses.map((course) => (
                                    <MenuItem key={course.id} value={course.id}>
                                        {course.course_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="title"
                            label="Video Title"
                            name="title"
                            autoFocus
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </>
                );
            case 1:
                return (
                    <>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Video Type</InputLabel>
                            <Select
                                value={videoType}
                                onChange={(e) => setVideoType(e.target.value)}
                                label="Video Type"
                            >
                                <MenuItem value="youtube">YouTube URL</MenuItem>
                                <MenuItem value="mp4">MP4 Upload</MenuItem>
                            </Select>
                        </FormControl>

                        {videoType === 'youtube' ? (
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="url"
                                label="YouTube URL"
                                name="url"
                                value={url}
                                onChange={(e) => setUrl(e.target.value)}
                            />
                        ) : (
                            <Button
                                component="label"
                                variant="outlined"
                                startIcon={<CloudUploadIcon />}
                                sx={{ mt: 2, mb: 1 }}
                                fullWidth
                            >
                                {videoFile ? (
                                    videoFile.existing ? `Current: ${videoFile.name}` : videoFile.name
                                ) : 'Upload MP4 Video'}
                                <input
                                    type="file"
                                    hidden
                                    accept="video/mp4"
                                    onChange={handleVideoFileChange}
                                />
                            </Button>
                        )}

                        <label htmlFor="transcript-file">
                            <Button
                                component="span"
                                fullWidth
                                variant="outlined"
                                sx={{ mt: 2, mb: 1 }}
                            >
                                {transcript ? (
                                    transcript.existing ? `Current: ${transcript.name}` : transcript.name
                                ) : `Upload Transcript (PDF or TXT) ${editMode ? '' : '*'}`}
                            </Button>
                        </label>
                        <input
                            accept=".pdf,.txt"
                            style={{ display: 'none' }}
                            id="transcript-file"
                            type="file"
                            onChange={handleFileChange}
                            required={!editMode}
                        />
                    </>
                );
            case 2:
                return renderSupportingDocuments();
            default:
                return null;
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        if (activeStep < 2) {
            handleNext();
            return;
        }

        setIsLoading(true);
        setError('');
        setSuccess('');

        if (!user?.user_id) {
            setError('User not authenticated');
            setIsLoading(false);
            return;
        }

        try {
            const selectedCourseData = courses.find(course => course.id === selectedCourse);
            if (!selectedCourseData) {
                setError('Please select a valid course');
                setIsLoading(false);
                return;
            }

            const formData = new FormData();
            formData.append('title', title);
            formData.append('videoType', videoType);
            formData.append('associated_course', selectedCourseData.course_name);
            formData.append('associated_course_id', selectedCourseData.id);
            formData.append('who_created', user.user_id);
            formData.append('date_created', new Date().toISOString().slice(0, 19).replace('T', ' '));

            if (videoType === 'youtube') {
                formData.append('url', url);
            } else if (videoFile) {
                formData.append('videoFile', videoFile);
            }

            if (transcript && !transcript.existing) {
                formData.append('transcript', transcript);
            }

            if (editMode && docsToKeep.length > 0) {
                formData.append('existingDocs', JSON.stringify(docsToKeep));
            }

            newSupportingDocs.forEach((file, index) => {
                formData.append(`supportingDoc_${index}`, file);
            });

            formData.append('send_email', sendEmailNotification ? '1' : '0');

            const endpoint = editMode 
                ? `${BASE_URL}/api/lmsvideo/${selectedVideo.id}`
                : `${BASE_URL}/api/submitlmsvideo`;

            const response = await fetch(endpoint, {
                method: editMode ? 'PUT' : 'POST',
                headers: {
                    'Authorization': `Bearer ${getToken()}`
                },
                body: formData
            });

            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'Failed to submit video');
            }

            setSuccess(editMode ? 'Video updated successfully!' : 'Video added successfully!');
            handleCloseModal(); // Close modal after successful submission
           // fetchVideos();
        } catch (error) {
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const resetForm = () => {
        setTitle('');
        setUrl('');
        setVideoFile(null);
        setTranscript(null);
        setSelectedCourse('');
        setEditMode(false);
        setSelectedVideo(null);
        setVideoType('youtube');
        setSupportingDocs([]);
        setNewSupportingDocs([]);
        setDocsToKeep([]);
        setActiveStep(0);
        setSendEmailNotification(false);
    };

    const handleVideoFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type === 'video/mp4') {
            setVideoFile(file);
        } else {
            setError('Please upload an MP4 file');
            event.target.value = null;
        }
    };

    const renderSupportingDocuments = () => (
        <Box sx={{ mt: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
                Supporting Documents
            </Typography>
            
            {/* Existing Documents */}
            {supportingDocs.length > 0 && (
                <Box sx={{ mb: 2 }}>
                   
                    {supportingDocs.map((doc) => {
                        const isKept = docsToKeep.find(d => d.id === doc.id)?.keep !== false;
                        return (
                            <Box 
                                key={doc.id}
                                sx={{ 
                                    display: 'flex',
                                    alignItems: 'center',
                                    mb: 1,
                                    opacity: isKept ? 1 : 0.5
                                }}

                            >
                                <Typography variant="body2" sx={{ flex: 1 }}>
                                    {doc.document_name}
                                </Typography>
                                <IconButton 
                                    size="small"
                                    onClick={() => handleSupportingDocDelete(doc.id)}
                                    color={isKept ? "error" : "primary"}
                                >
                                    {isKept ? <DeleteIcon /> : <UndoIcon />}
                                </IconButton>
                            </Box>
                        );
                    })}
                </Box>
            )}

            {/* New Documents */}
            {newSupportingDocs.length > 0 && (
                <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" gutterBottom>
                        New Documents:
                    </Typography>
                    {newSupportingDocs.map((file, index) => (
                        <Box 
                            key={index}
                            sx={{ 
                                display: 'flex',
                                alignItems: 'center',
                                mb: 1
                            }}
                        >
                            <Typography variant="body2" sx={{ flex: 1 }}>
                                {file.name}
                            </Typography>
                            <IconButton 
                                size="small"
                                onClick={() => handleNewSupportingDocDelete(index)}
                                color="error"
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    ))}
                </Box>
            )}

            {/* Upload Button */}
            <Button
                component="label"
                variant="outlined"
                startIcon={<CloudUploadIcon />}
                fullWidth
                sx={{ mb: 2 }}
            >
                Add Supporting Documents
                <input
                    type="file"
                    hidden
                    multiple
                    accept=".pdf,.txt,.doc,.docx,.csv"
                    onChange={handleSupportingDocsChange}
                />
            </Button>

            {/* Add Email Notification Toggle */}
            <FormControlLabel
                control={
                    <Switch
                        checked={sendEmailNotification}
                        onChange={(e) => setSendEmailNotification(e.target.checked)}
                        name="sendEmailNotification"
                        color="primary"
                    />
                }
                label="Send email notification to course users"
                sx={{ mt: 2, display: 'block' }}
            />
        </Box>
    );

    return (
        <Box sx={{
            backgroundColor:'background.default',
            flexGrow: 1,
            minHeight: '100vh',
            overflow: 'auto',
        }}>
            <CssBaseline />
            <Toolbar />
            <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
                <Paper sx={{ p: 4 }}>
                    <Typography variant="h4" gutterBottom>
                        Add New Video
                    </Typography>

                    <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 3 }}>
                        {['Course & Title', 'Video Details', 'Supporting Documents'].map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>

                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        {renderStepContent()}

                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                            {activeStep > 0 && (
                                <Button 
                                    onClick={handleBack} 
                                    variant="outlined"
                                    sx={{ mr: 1 }}
                                >
                                    Back
                                </Button>
                            )}

                            <Button
                                type="submit"
                                variant="contained"
                                disabled={
                                    isLoading || 
                                    (activeStep === 0 && !isStepOneValid()) || 
                                    (activeStep === 1 && !isStepTwoValid())
                                }
                            >
                                {activeStep === 2 
                                    ? (isLoading ? 'Processing...' : 'Add Video')
                                    : 'Next'}
                            </Button>
                        </Box>
                    </Box>
                </Paper>

                <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
                    <Alert onClose={() => setError('')} severity="error" sx={{ width: '100%' }}>
                        {error}
                    </Alert>
                </Snackbar>

                <Snackbar open={!!success} autoHideDuration={6000} onClose={() => setSuccess('')}>
                    <Alert onClose={() => setSuccess('')} severity="success" sx={{ width: '100%' }}>
                        {success}
                    </Alert>
                </Snackbar>
            </Container>
        </Box>
    );
}

export default LMSAddVideos;

