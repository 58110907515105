import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
    Box, Container, Typography, Grid, Card, CardContent, 
    CardActionArea, CircularProgress, List, ListItem, 
    ListItemText, Divider, Snackbar, Alert, Accordion, AccordionSummary, AccordionDetails, Button, Paper, IconButton, Collapse, LinearProgress, CssBaseline, Toolbar, Tabs, Tab, CardMedia, Chip 
} from '@mui/material';
import { getToken, getUserId, getRoleId, getClientId, isSuperAdmin } from '../auth/auth.js';
import { BASE_URL } from '../Constants';
import { useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { styled } from '@mui/material/styles';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import NotificationsIcon from '@mui/icons-material/Notifications';

const PlaceholderBox = styled(Box)(({ theme }) => ({
    height: 140,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.mode === 'dark' 
        ? 'rgba(255, 255, 255, 0.05)' 
        : 'rgba(0, 0, 0, 0.03)',
    borderBottom: `1px solid ${theme.palette.mode === 'dark' 
        ? 'rgba(255, 255, 255, 0.1)' 
        : 'rgba(0, 0, 0, 0.1)'}`,
}));

function CourseList() {
    const [courses, setCourses] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [videos, setVideos] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');
    const [expandedCourse, setExpandedCourse] = useState(null);
    const [showFullDescription, setShowFullDescription] = useState({});
    const [courseProgress, setCourseProgress] = useState({});
    const [activeTab, setActiveTab] = useState('enrolled');
    const [enrolledCourses, setEnrolledCourses] = useState([]);

    const navigate = useNavigate();
    const theme = useTheme();
    const token = getToken();

    useEffect(() => {
        fetchCourses();
        fetchEnrolledCourses();
        console.log("All courses:", courses); // Debug log
    }, [token]);

    useEffect(() => {
        if (courses.length > 0) {
            courses.forEach(course => {
                fetchCourseProgress(course.id);
            });
        }
    }, [courses]);

    const fetchCourses = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/courses`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch courses');
            }
            const data = await response.json();
            setCourses(data);
            data.forEach(course => {
                fetchCourseProgress(course.id);
            });
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching courses:', error);
            setSnackbarMessage('Error loading courses');
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
            setIsLoading(false);
        }
    };

    const fetchVideos = async (courseId) => {
        try {
            const response = await fetch(`${BASE_URL}/api/getlmsvideos?course_id=${courseId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to fetch videos');
            }
            const data = await response.json();
            console.log('Fetched videos:', data);
            setVideos(data);
            setSelectedCourse(courseId);
        } catch (error) {
            console.error('Error fetching videos:', error);
            setSnackbarMessage(error.message);
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
        }
    };

    const handleVideoClick = (video, courseId) => {

        navigate(`/video-player`, {
            state: { 
                video,
                courseId,
                courseName: selectedCourse,
                videos: videos
            }
        });
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setOpenSnackbar(false);
    };

    const fetchCourseProgress = async (courseId) => {
        try {
            const response = await fetch(`${BASE_URL}/api/course-progress/${courseId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });
            if (!response.ok) throw new Error('Failed to fetch progress');
            const data = await response.json();
            
            // Filter out videos with no duration
            const validVideos = data.filter(video => video.total_duration > 0);
            
            if (validVideos.length === 0) {
                setCourseProgress(prev => ({
                    ...prev,
                    [courseId]: {
                        percent: 0,
                        videos: data
                    }
                }));
                return;
            }

            // Calculate weight per video (equal distribution)
            const weightPerVideo = 100 / data.length;

            // Calculate weighted progress for each video
            let totalProgress = 0;
            data.forEach(video => {
                if (!video.total_duration) return;
                const individualProgress = Math.min(
                    (video.watched_duration / video.total_duration) * 100, 
                    100
                );
                const weightedProgress = (individualProgress * weightPerVideo) / 100;
                totalProgress += weightedProgress;
            });

            setCourseProgress(prev => ({
                ...prev,
                [courseId]: {
                    percent: Math.min(totalProgress, 100),
                    videos: data
                }
            }));
        } catch (error) {
            console.error('Error fetching course progress:', error);
        }
    };

    const CourseProgress = ({ courseId }) => {
        const progress = courseProgress[courseId];
        
        return (
            <Box sx={{ mt: 2, mb: 1 }}>
                <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center',
                    mb: 1 
                }}>
                    <Typography 
                        variant="body2" 
                        color="text.secondary"
                        sx={{ fontSize: '0.875rem' }}
                    >
                        Progress
                    </Typography>
                    <Typography 
                        variant="body2" 
                        sx={{ 
                            color: 'primary.main',
                            fontWeight: 500,
                            fontSize: '0.875rem'
                        }}
                    >
                        {progress ? Math.round(progress.percent) : 0}%
                    </Typography>
                </Box>
                <LinearProgress 
                    variant="determinate" 
                    value={progress ? progress.percent : 0}
                    sx={{
                        height: 4,
                        borderRadius: 2,
                        backgroundColor: 'rgba(255, 255, 255, 0.08)',
                        '& .MuiLinearProgress-bar': {
                            borderRadius: 2,
                            backgroundColor: 'primary.main',
                        }
                    }}
                />
            </Box>
        );
    };

    const getVideoProgress = (videoId, courseId) => {
        const progress = courseProgress[courseId];
        if (!progress || !progress.videos) return 0;
        
        const video = progress.videos.find(v => v.video_id === videoId);
        if (!video || !video.total_duration || video.total_duration <= 0) {
            console.log(`No valid duration for video ${videoId}:`, video);
            return 0;
        }
        
        const percentage = (video.watched_duration / video.total_duration) * 100;
        console.log(`Progress for video ${videoId}:`, {
            watched: video.watched_duration,
            total: video.total_duration,
            percentage
        });
        
        return Math.min(percentage, 100);
    };

    const handleCourseClick = (course) => {
        // Skip enrollment check for super admins
        if (isSuperAdmin()) {
            navigate(`/course-details/${course.id}`, {
                state: { 
                    course,
                    courseId: course.id,
                    courseName: course.course_name
                }
            });
            return;
        }

        // Check enrollment for other users
        const isEnrolled = enrolledCourses.some(ec => ec.id === course.id);
        
        if (!isEnrolled) {
            setSnackbarMessage('Please enroll in the course first');
            setSnackbarSeverity('info');
            setOpenSnackbar(true);
            return;
        }

        navigate(`/course-details/${course.id}`, {
            state: { 
                course,
                courseId: course.id,
                courseName: course.course_name
            }
        });
    };

    const fetchEnrolledCourses = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/enrolled-courses`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) throw new Error('Failed to fetch enrolled courses');
            const data = await response.json();
            setEnrolledCourses(data);
        } catch (error) {
            console.error('Error fetching enrolled courses:', error);
            setSnackbarMessage('Error loading enrolled courses');
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
        }
    };

    const handleEnrollCourse = async (courseId) => {
        try {
            const response = await fetch(`${BASE_URL}/api/enroll-course/${courseId}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'Failed to enroll');
            }

            // Show success message
            setSnackbarMessage('Successfully enrolled in course');
            setSnackbarSeverity('success');
            setOpenSnackbar(true);

            // Refresh both course lists
            fetchEnrolledCourses();
            fetchCourses();
        } catch (error) {
            console.error('Error enrolling in course:', error);
            setSnackbarMessage(error.message);
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
        }
    };

    const handleNotifyUsers = async (courseId) => {
        try {
            const response = await fetch(`${BASE_URL}/api/notify-course-users/${courseId}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            const data = await response.json();
            
            if (!response.ok) {
                throw new Error(data.error || 'Failed to send notifications');
            }

            setSnackbarMessage(`Notifications sent successfully to ${data.recipientCount} users`);
            setSnackbarSeverity('success');
            setOpenSnackbar(true);
        } catch (error) {
            console.error('Error sending notifications:', error);
            setSnackbarMessage(error.message);
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
        }
    };

    const handleResume = async (courseId, e) => {
        e.stopPropagation();
        try {
            const response = await fetch(`${BASE_URL}/api/last-watched/${courseId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch last watched video');
            }

            const data = await response.json();
            
            if (data.video) {
                navigate('/video-player', {
                    state: {
                        video: {
                            ...data.video,
                            videoType: data.video.mp4_path ? 'mp4' : 'youtube'
                        },
                        courseId: data.courseId,
                        courseName: data.course.course_name,
                        courseDescription: data.course.course_description,
                        videos: data.videos.map(v => ({
                            ...v,
                            videoType: v.mp4_path ? 'mp4' : 'youtube'
                        })),
                        startTime: parseInt(data.startTime),
                        course: data.course,
                        lastWatchedPosition: parseInt(data.startTime)
                    }
                });
            } else {
                setSnackbarMessage('No videos available in this course');
                setSnackbarSeverity('info');
                setOpenSnackbar(true);
            }
        } catch (error) {
            console.error('Error resuming course:', error);
            setSnackbarMessage('Failed to resume course');
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
        }
    };

    const renderCourseList = (courses) => (
        <Grid container spacing={3}>
            {courses.map((course) => {
                const isEnrolled = enrolledCourses.some(ec => ec.id === course.id);
                const isSuperAdminUser = isSuperAdmin();
                
                return (
                    <Grid item xs={12} sm={6} md={4} key={course.id}>
                        <Card 
                            sx={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                position: 'relative'
                            }}
                        >
                            <Box 
                                sx={{ 
                                    position: 'absolute',
                                    top: 16,
                                    right: 16,
                                    zIndex: 1
                                }}
                            >
                                <Chip
                                    label={course.is_public ? "Public" : "Private"}
                                    size="small"
                                    color={course.is_public ? "success" : "default"}
                                    sx={{ 
                                        backgroundColor: course.is_public 
                                            ? 'rgba(46, 125, 50, 0.9)' 
                                            : 'rgba(0, 0, 0, 0.6)',
                                        color: 'white'
                                    }}
                                />
                            </Box>

                            {/* Wrap content in CardActionArea if enrolled or super admin */}
                            {(isEnrolled || isSuperAdminUser) ? (
                                <CardActionArea 
                                    onClick={() => handleCourseClick(course)}
                                    sx={{
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'stretch'
                                    }}
                                >
                                    {renderCourseContent(course)}
                                </CardActionArea>
                            ) : (
                                <Box
                                    sx={{
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'stretch'
                                    }}
                                >
                                    {renderCourseContent(course)}
                                </Box>
                            )}

                            <CardContent sx={{ 
                                flexGrow: 1, 
                                pb: 2,
                                display: 'flex',
                                flexDirection: 'column'
                            }}>
                                <Typography variant="h6" component="div" gutterBottom>
                                    {course.course_name}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    sx={{
                                        display: '-webkit-box',
                                        WebkitLineClamp: 3,
                                        WebkitBoxOrient: 'vertical',
                                        overflow: 'hidden'
                                    }}
                                >
                                    {course.course_description || "No description available."}
                                </Typography>
                            </CardContent>

                            {/* Progress and buttons section */}
                            <Box sx={{ p: 2, pt: 0 }}>
                                {/* Progress bar for enrolled courses */}
                                {enrolledCourses.some(ec => ec.id === course.id) && (
                                    <>
                                        <Box sx={{ mb: 2 }}>
                                            <CourseProgress courseId={course.id} />
                                        </Box>
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            startIcon={<PlayArrowIcon />}
                                            onClick={(e) => handleResume(course.id, e)}
                                            sx={{ mb: 1 }}
                                        >
                                            Resume Course
                                        </Button>
                                    </>
                                )}

                                {/* Existing Enroll Now button */}
                                {activeTab === 'public' && !enrolledCourses.some(ec => ec.id === course.id) && (
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleEnrollCourse(course.id);
                                        }}
                                    >
                                        Enroll Now
                                    </Button>
                                )}

                                {/* Existing Notify button */}
                                {(getRoleId() === 1 || getRoleId() === 2) && 
                                 (activeTab === 'public' || activeTab === 'private') && (
                                    <Button
                                        variant="outlined"
                                        fullWidth
                                        startIcon={<NotificationsIcon />}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleNotifyUsers(course.id);
                                        }}
                                        sx={{ mt: activeTab === 'public' ? 1 : 0 }}
                                    >
                                        Notify Users
                                    </Button>
                                )}
                            </Box>
                        </Card>
                    </Grid>
                );
            })}
        </Grid>
    );

    // Add this helper function to render course content
    const renderCourseContent = (course) => (
        <>
            {course.image_path ? (
                <CardMedia
                    component="img"
                    sx={{ 
                        height: 200,
                        objectFit: 'cover',
                        width: '100%'
                    }}
                    image={`${BASE_URL}/uploads${course.image_path}`}
                    alt={course.course_name}
                    onError={(e) => {
                        e.target.style.display = 'none';
                        e.target.parentElement.appendChild(
                            document.createElement('div')
                        ).className = 'placeholder-image';
                        e.target.parentElement.lastChild.innerHTML = `
                            <div style="
                                height: 200px; 
                                display: flex; 
                                align-items: center; 
                                justify-content: center; 
                                background-color: ${theme.palette.mode === 'dark' 
                                    ? 'rgba(255, 255, 255, 0.05)' 
                                    : 'rgba(0, 0, 0, 0.03)'};
                                width: 100%;
                            ">
                                <AutoStoriesIcon style="width: 48px; height: 48px; opacity: 0.5;" />
                            </div>`;
                    }}
                />
            ) : (
                <PlaceholderBox
                    sx={{ 
                        height: 200,
                        width: '100%',
                        backgroundColor: theme.palette.mode === 'dark' 
                            ? 'rgba(255, 255, 255, 0.05)' 
                            : 'rgba(0, 0, 0, 0.03)',
                    }}
                >
                    <AutoStoriesIcon 
                        sx={{ 
                            width: 48, 
                            height: 48, 
                            opacity: 0.5,
                            color: theme.palette.text.secondary
                        }} 
                    />
                </PlaceholderBox>
            )}
        </>
    );

    // Update the filtering logic
    const filterCourses = () => {
        const userRoleId = getRoleId();
        const userClientId = getClientId();
        const userId = getUserId();

        // Super admin sees all courses
        if (isSuperAdmin()) {
            return {
                publicCourses: courses.filter(course => course.is_public),
                privateCourses: courses.filter(course => !course.is_public)
            };
        }

        // Admin sees all courses within their client_id
        if (userRoleId === 2 || userRoleId === 3) {
            return {
                publicCourses: courses.filter(course => 
                    course.is_public && 
                    course.access_clients.includes(Number(userClientId))
                ),
                privateCourses: courses.filter(course => 
                    !course.is_public && 
                    course.access_clients.includes(Number(userClientId))
                )
            };
        }

        // Regular users see public courses for their client and private courses they're assigned to
        return {
            publicCourses: courses.filter(course => 
                course.is_public && 
                course.access_clients.includes(Number(userClientId))
            ),
            privateCourses: courses.filter(course => 
                !course.is_public && 
                course.assigned_users && 
                Array.isArray(course.assigned_users) &&
                course.assigned_users.some(user => Number(user.user_id) === Number(userId))
            )
        };
    };

    // In your render logic:
    const { publicCourses, privateCourses } = filterCourses();

    // Add this helper function to get the appropriate section title
    const getPrivateCoursesTitle = () => {
        if (isSuperAdmin()) {
            return "Private Courses";
        } else if (getRoleId() === 2) { // Admin
            return "Your Private Courses";
        } else {
            return "Your Private Courses";
        }
    };

    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{ backgroundColor: 'background.default', flexGrow: 1, minHeight: '100vh' }}>
            <CssBaseline />
            <Toolbar />
            <Container maxWidth="lg">
                <Typography 
                    variant="h4" 
                    gutterBottom 
                    sx={{ 
                        textAlign: 'center',
                        mb: 4,
                        fontWeight: 500
                    }}
                >
                    Course Library
                </Typography>

                <Tabs 
                    value={activeTab}
                    onChange={(e, newValue) => setActiveTab(newValue)}
                    sx={{ mb: 3 }}
                >
                    <Tab label="Enrolled Courses" value="enrolled" />
                    <Tab label="Public Courses" value="public" />
                    {privateCourses.length > 0 && (
                        <Tab label={getPrivateCoursesTitle()} value="private" />
                    )}
                </Tabs>

                {activeTab === 'enrolled' && renderCourseList(enrolledCourses)}
                {activeTab === 'public' && renderCourseList(publicCourses)}
                {activeTab === 'private' && renderCourseList(privateCourses)}
            </Container>

            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default CourseList; 