import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  CircularProgress,
  Collapse,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { BASE_URL } from '../Constants';
import { getToken } from '../auth/auth';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

const stripePromise = loadStripe('pk_test_51LM04hJhfJWuhodDRW7vlG354oYXNNverVbhRdXBQq3zdX3KfkV7cZuc19swryFEPPyEcweaA3n8HNBH5ea66Fd000DJk5YiX3');

const cardStyle = {
  style: {
    base: {
      color: '#fff',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  },
  hidePostalCode: true
};

function PricingTiers({ pricingTiers, activeSubscription, onSelectTier, onCancelSubscription }) {
  // Helper function to convert tier name to backend key
  const getTierKey = (name) => {
    console.log('Converting tier name:', name);
    
    switch (name) {
      case 'Free':
        return 'FREE';
      case 'Tier One':
        return 'TIER_ONE';
      case 'Tier Two':
        return 'TIER_TWO';
      default:
        return null;
    }
  };

  const handleCancelSubscription = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/cancel-subscription`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${getToken()}`,
          'Content-Type': 'application/json'
        }
      });

      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.error || 'Failed to cancel subscription');
      }

      // Update all buttons state by updating the activeSubscription
      onCancelSubscription({
        ...activeSubscription,
        status: 'cancelled',
        endDate: data.cancelDate,
        isActive: true // Add this to indicate subscription is still active
      });

      alert('Your subscription has been cancelled and will end on ' + 
            new Date(data.cancelDate).toLocaleDateString());

    } catch (err) {
      console.error('Error cancelling subscription:', err);
      alert('Failed to cancel subscription: ' + err.message);
    }
  };

  return (
    <Grid container spacing={3} justifyContent="center">
      {Object.entries(pricingTiers).map(([key, tier]) => {
        const isActive = activeSubscription?.tier === key;
        const isCancelled = activeSubscription?.status === 'cancelled';
        const hasActiveSubscription = activeSubscription?.isActive;
        const currentTierPrice = pricingTiers[activeSubscription?.tier]?.basePrice || 0;
        const newTierPrice = tier.basePrice || 0;
        const now = new Date();
        const endDate = activeSubscription?.endDate ? new Date(activeSubscription.endDate) : null;
        const isExpired = endDate && endDate <= now;

        // Updated disabled logic
        const isDisabled = 
          (isActive && isCancelled) || // Disable cancelled active plan
          (!isActive && tier.price === 0) || // Disable free plan selection
          (isActive && tier.price === 0) || // Disable if current plan is free
          (hasActiveSubscription && isCancelled) || // Disable all plans during cancellation period
          (hasActiveSubscription && !isCancelled && !isActive && newTierPrice < currentTierPrice); // No downgrade for active

        // Updated button text logic
        const getButtonText = () => {
          if (isActive && isCancelled) {
            return `Active until ${endDate.toLocaleDateString()}`;
          }
          if (isActive && !isCancelled) {
            return 'Cancel Subscription';
          }
          if (hasActiveSubscription && isCancelled) {
            return `Available after ${endDate.toLocaleDateString()}`;
          }
          if (hasActiveSubscription && newTierPrice < currentTierPrice) {
            return 'Downgrade not allowed';
          }
          if (hasActiveSubscription && newTierPrice > currentTierPrice) {
            return 'Upgrade Plan';
          }
          return tier.price === 0 ? 'Free Plan' : 'Subscribe';
        };

        const handleButtonClick = () => {
          if (isDisabled) {
            return;
          }

          if (isActive && !isCancelled) {
            handleCancelSubscription();
          } else {
            onSelectTier({
              ...tier,
              tierKey: key
            });
          }
        };

        return (
          <Grid item key={key} xs={12} sm={6} md={4} sx={{ maxWidth: '320px' }}>
            <Card
              sx={{
                height: '100%',
                backgroundColor: '#2A2A2A',
                color: 'white',
                borderRadius: '16px',
                boxShadow: isActive ? '0 0 0 2px #8B5CF6' : '0 8px 32px rgba(0, 0, 0, 0.1)',
                position: 'relative',
                opacity: isDisabled ? 0.7 : 1,
              }}
            >
              {isActive && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 16,
                    right: 16,
                    backgroundColor: '#8B5CF6',
                    color: 'white',
                    padding: '4px 12px',
                    borderRadius: '12px',
                    fontSize: '0.75rem',
                  }}
                >
                  {isCancelled ? 'Cancelled' : 'Current Plan'}
                </Box>
              )}

              <CardContent sx={{ p: 2.5 }}>
                <Typography variant="h3" sx={{ 
                  mb: 3, 
                  color: '#8B5CF6', 
                  fontSize: '1.5rem', 
                  fontWeight: 400 
                }}>
                  {tier.name}
                </Typography>

                <Box sx={{ mb: 3 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                    <Typography sx={{ 
                      fontSize: '2.5rem', 
                      color: '#8B5CF6', 
                      lineHeight: 1 
                    }}>
                      {tier.price === 0 ? 'Free' : (
                        <>
                          <span style={{ fontSize: '2rem' }}>$</span>
                          {tier.basePrice}
                        </>
                      )}
                    </Typography>
                    {tier.price !== 0 && (
                      <Typography sx={{ color: '#9CA3AF', fontSize: '0.875rem' }}>
                        /month, billed yearly
                      </Typography>
                    )}
                  </Box>
                  {tier.price !== 0 && (
                    <Typography sx={{ color: '#9CA3AF', fontSize: '0.875rem' }}>
                      +$1/user monthly
                    </Typography>
                  )}
                </Box>

                <List sx={{ mb: 3 }}>
                  {tier.features.map((feature) => (
                    <ListItem key={feature} sx={{ px: 0, py: 0.75 }}>
                      <ListItemIcon sx={{ minWidth: 28 }}>
                        <CheckIcon sx={{ color: '#8B5CF6', fontSize: '1.25rem' }} />
                      </ListItemIcon>
                      <ListItemText primary={feature} sx={{ 
                        '& .MuiListItemText-primary': { 
                          color: 'white', 
                          fontSize: '0.875rem' 
                        }
                      }} />
                    </ListItem>
                  ))}
                </List>

                {isActive && isCancelled ? (
                  <Box
                    sx={{
                      py: 1,
                      px: 2,
                      borderRadius: '12px',
                      backgroundColor: '#2A2A2A',
                      color: 'rgba(255, 255, 255, 0.5)',
                      textAlign: 'center',
                      fontSize: '0.875rem',
                    }}
                  >
                    Active until {endDate.toLocaleDateString()}
                  </Box>
                ) : (
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{
                      backgroundColor: isActive ? '#2A2A2A' : '#8B5CF6',
                      color: 'white',
                      py: 1,
                      px: 2,
                      borderRadius: '12px',
                      textTransform: 'none',
                      fontSize: '0.875rem',
                      '&:hover': { 
                        backgroundColor: isActive && !isCancelled ? '#2A2A2A' : '#6B3FEF' 
                      },
                      '&.Mui-disabled': {
                        backgroundColor: '#2A2A2A',
                        color: 'rgba(255, 255, 255, 0.5)',
                        cursor: 'not-allowed'
                      }
                    }}
                    onClick={handleButtonClick}
                    disabled={isDisabled}
                  >
                    {getButtonText()}
                  </Button>
                )}
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
}

function PaymentForm({ selectedTier, onCancel, onSuccess }) {
  const stripe = useStripe();
  const elements = useElements();
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) return;

    setProcessing(true);
    setError(null);

    try {
      console.log('Selected tier:', selectedTier); // Debug log
      console.log('Tier key being sent:', selectedTier.tierKey); // Debug log

      const { error: cardError, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      });

      if (cardError) throw new Error(cardError.message);

      // Ensure we have a valid tier key
      if (!selectedTier.tierKey) {
        throw new Error('Invalid tier selected');
      }

      const response = await fetch(`${BASE_URL}/api/create-subscription`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getToken()}`
        },
        body: JSON.stringify({
          tier: selectedTier.tierKey,
          paymentMethodId: paymentMethod.id
        })
      });

      const data = await response.json();
      if (!response.ok) throw new Error(data.error || 'Failed to create subscription');

      if (data.clientSecret) {
        const { error: confirmError } = await stripe.confirmCardPayment(data.clientSecret);
        if (confirmError) throw new Error(confirmError.message);
      }

      onSuccess();
      onCancel();
      alert(data.message || 'Subscription updated successfully!');
      
    } catch (err) {
      console.error('Subscription error:', err);
      setError(err.message);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 400, mx: 'auto' }}>
      <Typography variant="h5" sx={{ mb: 3, color: 'white' }}>
        Subscribe to {selectedTier.name}
      </Typography>
      
      <Box sx={{ mb: 3, p: 2, borderRadius: '8px', backgroundColor: 'rgba(255, 255, 255, 0.05)' }}>
        <CardElement options={cardStyle} />
      </Box>

      {error && (
        <Typography color="error" sx={{ mb: 2, fontSize: '0.875rem' }}>
          {error}
        </Typography>
      )}

      <Box sx={{ display: 'flex', gap: 2 }}>
        <Button
          variant="outlined"
          onClick={onCancel}
          sx={{
            flex: 1,
            color: 'white',
            borderColor: '#8B5CF6',
            '&:hover': { borderColor: '#7C3AED' }
          }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          disabled={!stripe || processing}
          sx={{
            flex: 1,
            backgroundColor: '#8B5CF6',
            '&:hover': { backgroundColor: '#7C3AED' }
          }}
        >
          {processing ? <CircularProgress size={20} /> : 'Pay Now'}
        </Button>
      </Box>
    </Box>
  );
}

function Pricing() {
  const [selectedTier, setSelectedTier] = useState(null);
  const [pricingTiers, setPricingTiers] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeSubscription, setActiveSubscription] = useState(null);

  const fetchCurrentSubscription = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/current-subscription`, {
        headers: {
          'Authorization': `Bearer ${getToken()}`,
          'Content-Type': 'application/json'
        }
      });
      
      if (!response.ok) {
        throw new Error('Failed to fetch subscription information');
      }
      
      const data = await response.json();
      setActiveSubscription(data);
    } catch (err) {
      console.error('Error fetching subscription:', err);
    }
  };

  const handleSubscriptionSuccess = () => {
    fetchCurrentSubscription();
  };

  const handleCancelSubscription = (updatedSubscription) => {
    setActiveSubscription(updatedSubscription);
  };

  useEffect(() => {
    Promise.all([
      fetchPricingTiers(),
      fetchCurrentSubscription()
    ]).finally(() => setLoading(false));
  }, []);

  const fetchPricingTiers = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/pricing`, {
        headers: {
          'Authorization': `Bearer ${getToken()}`,
          'Content-Type': 'application/json'
        }
      });
      
      if (!response.ok) {
        throw new Error('Failed to fetch pricing information');
      }
      
      const data = await response.json();
      setPricingTiers(data);
    } catch (err) {
      setError(err.message);
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!pricingTiers) return null;

  return (
    <Elements stripe={stripePromise}>
      <Container maxWidth="md" sx={{ py: 8 }}>
        {selectedTier ? (
          <PaymentForm 
            selectedTier={selectedTier} 
            onCancel={() => setSelectedTier(null)}
            onSuccess={handleSubscriptionSuccess}
          />
        ) : (
          <PricingTiers 
            pricingTiers={pricingTiers}
            activeSubscription={activeSubscription}
            onSelectTier={setSelectedTier} 
            onCancelSubscription={handleCancelSubscription}
          />
        )}
      </Container>
    </Elements>
  );
}

export default Pricing; 