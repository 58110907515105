import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  CircularProgress
} from '@mui/material';
import { BASE_URL } from '../Constants';
import backboardLogo from '../backboard.png';

export default function OrganizationRegistration({ data }) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [formData, setFormData] = useState({
    email: data?.email || '',
    firstName: data?.firstName || '',
    lastName: data?.lastName || '',
    organizationName: ''
  });

  const handleChange = (e) => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch(`${BASE_URL}/api/register-organization`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.error || 'Registration failed');
      }

      setIsLoading(false);
      setIsSuccess(true);
      
      // Wait 2 seconds before redirecting
      setTimeout(() => {
        navigate('/login');
      }, 2000);
    } catch (error) {
      console.error('Registration error:', error);
      setIsLoading(false);
    }
  };

  // Get button text based on state
  const getButtonText = () => {
    if (isLoading) {
      return <CircularProgress size={24} color="inherit" />;
    }
    if (isSuccess) {
      return "Registration complete! Login link sent.";
    }
    return "Complete Registration";
  };

  return (
    <Box
      sx={{
        backgroundColor: '#1b1b1b',
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      }}
    >
      <Box sx={{ position: 'absolute', top: 20, left: 20 }}>
        <img 
          src={backboardLogo} 
          alt="BackBoard Logo" 
          style={{ width: '40px', height: 'auto' }}
        />
      </Box>

      <Container component="main" maxWidth="sm" sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <Box sx={{
          width: '100%',
          maxWidth: '400px',
          textAlign: 'center',
        }}>
          <Typography component="h1" variant="h3" sx={{ 
            mb: 2, 
            color: '#FFFFFF',
            fontWeight: 600
          }}>
            Complete Registration
          </Typography>

          <Typography variant="h6" sx={{ 
            mb: 4, 
            color: '#B0B0B0',
            fontWeight: 400
          }}>
            Set up your organization account
          </Typography>

          <Box component="form" onSubmit={handleSubmit} noValidate>
            <TextField
              margin="normal"
              required
              fullWidth
              name="email"
              value={formData.email}
              disabled
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'rgba(255, 255, 255, 0.05)',
                  borderRadius: '8px',
                  height: '48px',
                  '& fieldset': { borderColor: 'transparent' },
                  '&:hover fieldset': { borderColor: 'transparent' },
                  '&.Mui-focused fieldset': { borderColor: 'transparent' }
                },
                '& .MuiInputBase-input': { 
                  color: '#FFFFFF',
                  padding: '12px 16px'
                }
              }}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              name="firstName"
              placeholder="First Name"
              value={formData.firstName}
              onChange={handleChange}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'rgba(255, 255, 255, 0.05)',
                  borderRadius: '8px',
                  height: '48px',
                  '& fieldset': { borderColor: 'transparent' },
                  '&:hover fieldset': { borderColor: 'transparent' },
                  '&.Mui-focused fieldset': { borderColor: 'transparent' }
                },
                '& .MuiInputBase-input': { 
                  color: '#FFFFFF',
                  padding: '12px 16px'
                }
              }}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              name="lastName"
              placeholder="Last Name"
              value={formData.lastName}
              onChange={handleChange}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'rgba(255, 255, 255, 0.05)',
                  borderRadius: '8px',
                  height: '48px',
                  '& fieldset': { borderColor: 'transparent' },
                  '&:hover fieldset': { borderColor: 'transparent' },
                  '&.Mui-focused fieldset': { borderColor: 'transparent' }
                },
                '& .MuiInputBase-input': { 
                  color: '#FFFFFF',
                  padding: '12px 16px'
                }
              }}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              name="organizationName"
              placeholder="Organization Name"
              value={formData.organizationName}
              onChange={handleChange}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'rgba(255, 255, 255, 0.05)',
                  borderRadius: '8px',
                  height: '48px',
                  '& fieldset': { borderColor: 'transparent' },
                  '&:hover fieldset': { borderColor: 'transparent' },
                  '&.Mui-focused fieldset': { borderColor: 'transparent' }
                },
                '& .MuiInputBase-input': { 
                  color: '#FFFFFF',
                  padding: '12px 16px'
                }
              }}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={isLoading || isSuccess}
              sx={{ 
                mt: 3,
                mb: 2,
                height: '48px',
                backgroundColor: '#7B4FFF',
                color: '#FFFFFF',
                borderRadius: '8px',
                textTransform: 'none',
                fontSize: '1rem',
                '&:hover': {
                  backgroundColor: '#6B3FEF',
                },
                '&:disabled': {
                  backgroundColor: isSuccess ? 'rgba(255, 255, 255, 0.11)' : '#CCCCCC',
                  color: isSuccess ? 'rgba(255, 255, 255, 0.5)' : '#FFFFFF',
                }
              }}
            >
              {getButtonText()}
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
} 