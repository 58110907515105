import jwt_decode from 'jwt-decode';

export function getToken() {
  return  sessionStorage.getItem('token') || localStorage.getItem('token');
}

export function getUserId() {
  const token = getToken();
  const decodedToken = jwt_decode(token);
  console.log("decodedToken user_id", decodedToken.user_id);
  return decodedToken.user_id;
} 

export function getFarmId() {
  const token = getToken();
  const decodedToken = jwt_decode(token); 
  const farmId = decodedToken.farm_id; 
  return farmId;
} 

export function verifyToken() {
  const token = getToken();

  if(!token) {
    return false;
  }

  try {
    const decoded = jwt_decode(token);

    // Check if the token has expired
    if(decoded.exp < Date.now() / 1000) {
      return false;
    }

    return true;
  } catch(err) {
    return false;
  }
}

export function getRoleId() {
  const token = getToken();
  if (!token) return null;
  const decodedToken = jwt_decode(token);
  return decodedToken.role_id;
}

export function getClientId() {
  const token = getToken();
  if (!token) return null;
  const decodedToken = jwt_decode(token);
  return decodedToken.client_id;
}

export function isSuperAdmin() {
  return getRoleId() === 1;
}

export function isCompanyAdmin() {
  return getRoleId() === 2;
}