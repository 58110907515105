import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody'; 
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { BASE_URL } from '../../Constants.js';
import { getToken, getRoleId, getClientId } from '../../auth/auth.js';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import FormControl from '@mui/material/FormControl'; 
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem'; 
import sortData from '../common/sortColumns.js';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import TablePagination from '@mui/material/TablePagination';
import { theme } from '../../theme';

function ManageStudents() {

    const [studentData, setStudentData] = React.useState([]); 
    const [editingId, setEditingId] = React.useState(null);
    const [originalData, setOriginalData] = React.useState({});
    const [tempData, setTempData] = React.useState({});
    const [sortField, setSortField] = React.useState('');
    const [sortDirection, setSortDirection] = React.useState({
        last_name: 'desc',
        email: 'desc',
    }); 
    const [snackbar, setSnackbar] = React.useState({
        open: false,
        message: '',
        severity: 'success'
    });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage] = React.useState(5);

    const sortedStudentData = sortData(studentData, sortField, sortDirection[sortField]);

    const token = getToken();
    const roleId = getRoleId();
    const clientId = getClientId();
    const navigate = useNavigate();

    const fetchStudents = () => { 
        fetch(`${BASE_URL}/api/readstudents`, { 
            method: 'GET', 
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
        .then(response => {
            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
            return response.text(); 
        })
        .then(data => {
            try {
                const jsonData = JSON.parse(data);
                setStudentData(jsonData);
            } catch(e) {
                console.error("The response is not JSON. Data:", data);
            }
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }

    React.useEffect(() => {
        fetchStudents();
    }, []);

    const editUser = (id) => {
        setEditingId(id);
    }

    const cancelEditing = () => {
        setEditingId(null);
        setTempData({});
    };

    const handleSave = async (id) => {
        const currentRow = studentData.find(student => student.user_id === id);
        
        const dataToSend = {
            first_name: tempData.first_name ?? currentRow.first_name,
            last_name: tempData.last_name ?? currentRow.last_name,
            email: tempData.email ?? currentRow.email,
            role_id: tempData.role_id ?? currentRow.role_id
        };

        try {
            const response = await fetch(`${BASE_URL}/api/updatestudent/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(dataToSend),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();

            if (result.message === 'Data updated successfully') {
                setEditingId(null);
                setTempData({});
                fetchStudents();
                setSnackbar({
                    open: true,
                    message: 'User updated successfully!',
                    severity: 'success'
                });
            } else {
                throw new Error(result.error || 'Failed to update user data');
            }
        } catch (err) {
            console.error('An error occurred:', err);
            setSnackbar({
                open: true,
                message: err.message || 'An error occurred while saving. Please try again.',
                severity: 'error'
            });
        }
    };

    const handleSort = (field) => {
        const newDirection = sortDirection[field] === 'asc' ? 'desc' : 'asc';
        setSortDirection({
            ...sortDirection,
            [field]: newDirection
        });
        setSortField(field);
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    const paginatedData = sortedStudentData.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const getRoleDisplay = (roleId) => {
        switch (roleId) {
            case 1:
                return 'Super Admin';
            case 2:
                return 'Admin';
            case 3:
                return 'User';
            default:
                return 'Unknown';
        }
    };

    return (
        <Box sx={{
            backgroundColor: theme.palette.background.default,
            flexGrow: 1,
            minHeight: '100vh',
            overflow: 'auto',
        }}>
            <CssBaseline />
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center', 
                        }}>
                            <Typography variant="h6" component="h2" gutterBottom
                                sx={{
                                    color: theme.palette.text.primary,
                                    textAlign: 'center'
                                }}
                            >
                                All Students
                            </Typography>
                        </Box>

                        <TableContainer component={Paper} 
                            sx={{ 
                                maxHeight: '80vh', 
                                overflow: 'auto',
                                backgroundColor: theme.palette.background.paper,
                               
                            }}
                        >
                            <Table aria-label="student table" stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell 
                                            onClick={() => handleSort('last_name')}
                                            sx={{ 
                                                color: theme.palette.text.primary,
                                                backgroundColor: theme.palette.background.paper,
                                                fontWeight: 'bold',
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    backgroundColor: 'rgba(255, 255, 255, 0.08)'
                                                }
                                            }}
                                        >
                                            Name {sortDirection['last_name'] === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                        </TableCell>
                                        <TableCell
                                            onClick={() => handleSort('email')}
                                            sx={{ 
                                                color: theme.palette.text.primary,
                                                backgroundColor: theme.palette.background.paper,
                                                fontWeight: 'bold',
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    backgroundColor: 'rgba(255, 255, 255, 0.08)'
                                                }
                                            }}
                                        >
                                            E-Mail {sortDirection['email'] === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                        </TableCell>
                                        <TableCell 
                                            sx={{ 
                                                color: theme.palette.text.primary,
                                                backgroundColor: theme.palette.background.paper,
                                                fontWeight: 'bold',
                                                padding: '16px'
                                            }}
                                        >
                                            Role
                                        </TableCell>
                                        <TableCell 
                                            sx={{ 
                                                color: theme.palette.text.primary,
                                                backgroundColor: theme.palette.background.paper,
                                                fontWeight: 'bold',
                                                padding: '16px'
                                            }}
                                        >
                                            Edit
                                        </TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {paginatedData.map((row, index) => (
                                        <TableRow 
                                            key={row.user_id || index}
                                            sx={{ 
                                                '&:hover': { 
                                                    backgroundColor: 'rgba(255, 255, 255, 0.08)'
                                                },
                                                '& .MuiTableCell-root': {
                                                    color: theme.palette.text.primary,
                                                    borderBottom: `1px solid ${theme.palette.divider}`
                                                }
                                            }}
                                        >
                                            {/* Name */}
                                            <TableCell>
                                                {editingId === row.user_id ? (
                                                    <Box sx={{ display: 'flex', gap: 1 }}>
                                                        <input 
                                                            value={tempData?.first_name ?? row.first_name}
                                                            onChange={e => setTempData({...tempData, first_name: e.target.value})}
                                                            style={{ 
                                                                padding: '8px',
                                                                border: '1px solid rgba(255, 255, 255, 0.23)',
                                                                borderRadius: '4px',
                                                                width: '120px',
                                                                backgroundColor: 'transparent',
                                                                color: theme.palette.text.primary
                                                            }}
                                                        />
                                                        <input 
                                                            value={tempData?.last_name ?? row.last_name} 
                                                            onChange={e => setTempData({...tempData, last_name: e.target.value})}
                                                            placeholder="Last Name"
                                                            style={{ 
                                                                padding: '8px',
                                                                border: '1px solid #ccc',
                                                                borderRadius: '4px',
                                                                width: '120px'
                                                            }}
                                                        />
                                                    </Box>
                                                ) : (
                                                    `${row.first_name} ${row.last_name}`
                                                )}
                                            </TableCell>

                                            {/* Email */}
                                            <TableCell sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                                                {editingId === row.user_id ? (
                                                    <input 
                                                        value={tempData?.email ?? row.email} 
                                                        onChange={e => setTempData({...tempData, email: e.target.value})}
                                                        style={{ 
                                                            padding: '8px',
                                                            border: '1px solid #ccc',
                                                            borderRadius: '4px',
                                                            width: '100%',
                                                            height: '36px',
                                                            boxSizing: 'border-box'
                                                        }}
                                                    />
                                                ) : (
                                                    row.email
                                                )}
                                            </TableCell>

                                            {/* Role */}
                                            <TableCell sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                                                {editingId === row.user_id ? (
                                                    <FormControl fullWidth>
                                                        <Select
                                                            value={tempData?.role_id ?? row.role_id}
                                                            onChange={e => setTempData({...tempData, role_id: e.target.value})}
                                                            sx={{ 
                                                                minWidth: '120px',
                                                                height: '36px',
                                                                '.MuiOutlinedInput-input': { 
                                                                    padding: '8px',
                                                                    paddingRight: '32px',
                                                                },
                                                                fieldset: {
                                                                    height: '36px',
                                                                }
                                                            }}
                                                            size="small"
                                                        >
                                                            <MenuItem value={2}>Admin</MenuItem>
                                                            <MenuItem value={3}>User</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                ) : (
                                                    getRoleDisplay(row.role_id)
                                                )}
                                            </TableCell>

                                            {/* Edit */}
                                            <TableCell>  
                                                {editingId === row.user_id ? (
                                                    <>
                                                        <Button onClick={() => handleSave(row.user_id)} sx={{ mr: 1 }}>
                                                            Save
                                                        </Button>
                                                        <Button onClick={() => cancelEditing()}>
                                                            Cancel
                                                        </Button>
                                                    </>
                                                ) : (
                                                    <IconButton 
                                                        aria-label="edit" 
                                                        onClick={() => editUser(row.user_id)}
                                                        sx={{ 
                                                            color: theme.palette.text.secondary,
                                                            '&:hover': {
                                                                color: theme.palette.primary.main
                                                            }
                                                        }}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[5]}
                                component="div"
                                count={sortedStudentData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                sx={{
                                    color: theme.palette.text.primary,
                                    '& .MuiTablePagination-select': {
                                        color: theme.palette.text.primary
                                    },
                                    '& .MuiTablePagination-selectIcon': {
                                        color: theme.palette.text.primary
                                    }
                                }}
                            />
                        </TableContainer>

                      
                    </Grid>
                </Grid>
            </Container>
            <Snackbar 
                open={snackbar.open} 
                autoHideDuration={6000} 
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert 
                    onClose={handleCloseSnackbar} 
                    severity={snackbar.severity}
                    sx={{ width: '100%' }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default ManageStudents;