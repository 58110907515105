import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { 
    Box, Container, Typography, Paper, TextField, Button, 
    List, ListItem, ListItemText, Divider, CircularProgress,
    LinearProgress, Snackbar, Alert, IconButton, Tooltip, MenuItem, Select, Menu
} from '@mui/material';
import ReactPlayer from 'react-player';
import { getToken, getUserId } from '../auth/auth.js';
import { BASE_URL } from '../Constants';
import { useTheme } from '@mui/material/styles';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SendIcon from '@mui/icons-material/Send';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ChatIcon from '@mui/icons-material/Chat';

function ChatHistoryNavbar({ chatHistory, onThreadSelect, currentThreadId }) {
    const nonEmptyThreads = chatHistory.filter(thread => thread.messageCount > 0);

    return (
        <Box sx={{ 
            maxHeight: '200px',
            overflowY: 'auto',
            msOverflowStyle: 'none',  // Hide scrollbar in IE/Edge
            scrollbarWidth: 'none',   // Hide scrollbar in Firefox
            '&::-webkit-scrollbar': { // Hide scrollbar in Chrome/Safari/Webkit
                display: 'none'
            },
            borderRadius: '8px',
        }}>
            <List sx={{ 
                width: '100%', 
                bgcolor: 'background.paper',
                '& .MuiListItem-root': {
                    borderRadius: '8px',
                    mb: 0.5,
                    transition: 'background-color 0.2s ease',
                }
            }}>
                {nonEmptyThreads.map((thread) => (
                    <ListItem 
                        key={thread.threadId}
                        button 
                        onClick={() => onThreadSelect(thread.threadId)}
                        selected={thread.threadId === currentThreadId}
                        sx={{
                            '&.Mui-selected': {
                                backgroundColor: 'action.selected',
                                '&:hover': {
                                    backgroundColor: 'action.selected',
                                }
                            },
                            margin: '8px',
                            width: 'calc(100% - 16px)',
                            '&:hover': {
                                backgroundColor: 'action.hover',
                            }
                        }}
                    >
                        <ListItemText 
                            primary={`Chat ${new Date(thread.createdAt).toLocaleDateString()}`}
                            secondary={`${thread.lastMessage} (${thread.messageCount} messages)`}
                            sx={{
                                '& .MuiListItemText-primary': {
                                    fontWeight: thread.threadId === currentThreadId ? 500 : 400,
                                },
                                '& .MuiListItemText-secondary': {
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    opacity: 0.7
                                }
                            }}
                        />
                    </ListItem>
                ))}
            </List>
        </Box>
    );
}

function SupportingDocuments({ documents }) {
    if (!documents || documents.length === 0) return null;

    return (
        <Box sx={{ mt: 3 }}>
            <Paper sx={{ p: 2, backgroundColor: 'background.paper' }}>
                <Typography variant="h6" gutterBottom>
                    Supporting Documents
                </Typography>
                <List 
                    dense 
                    sx={{
                        // Calculate height based on number of documents
                        height: documents.length <= 2 ? 'auto' : '106px', // 58px per item (48px height + 10px margin)
                        overflowY: documents.length > 2 ? 'auto' : 'visible',
                        msOverflowStyle: 'none',
                        scrollbarWidth: 'none',
                        '&::-webkit-scrollbar': {
                            display: 'none'
                        }
                    }}
                >
                    {documents.map((doc, index) => (
                        <ListItem 
                            key={index}
                            component="a"
                            href={`${BASE_URL}/${doc.path}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ 
                                textDecoration: 'none',
                                color: 'primary.main',
                                borderRadius: 1,
                                transition: 'all 0.2s ease',
                                cursor: 'pointer',
                                mb: 1,
                                height: '38px', // Fixed height for each item
                                backgroundColor: 'action.hover',
                                '&:hover': { 
                                    backgroundColor: 'action.selected',
                                }
                            }}
                        >
                            <ListItemText
                                primary={doc.name}
                                sx={{
                                    '& .MuiListItemText-primary': {
                                        fontWeight: 500,
                                        color: 'text.primary'
                                    }
                                }}
                            />
                        </ListItem>
                    ))}
                </List>
            </Paper>
        </Box>
    );
}

function VideoPlayer() {
    const location = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();
    const { video, courseId, courseName, videos, startTime } = location.state || {};
    console.log('Video being passed to player:', video); // Debug log
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [chatHistory, setChatHistory] = useState([]);
    const [lmsThreadId, setLMSThreadId] = useState(null);
    const [isLoadingMessages, setIsLoadingMessages] = useState(true);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');
    const [showChatHistory, setShowChatHistory] = useState(false);
    const [player, setPlayer] = useState(null);
    const lastSentDurationRef = useRef({});
    const messagesEndRef = useRef(null);
    const progressInterval = useRef(null);
    const lastUpdateTime = useRef({});
    const [videoMenuAnchor, setVideoMenuAnchor] = useState(null);
    const [videoProgress, setVideoProgress] = useState({});
    const [lastWatchedPosition, setLastWatchedPosition] = useState(0);

    const token = getToken();
    const userId = getUserId();

    useEffect(() => {
        if (!video || !courseId) {
            navigate('/courses');
            return;
        }
        
        // Reset player when video changes
        setPlayer(null);
        
        // Only load thread and messages if not preserving chat
        if (!location.state?.preserveChat) {
            loadThreadAndMessages();
            fetchChatHistory();
        }
    }, [courseId, video?.id, location.state?.preserveChat]); // Add preserveChat to dependencies

    useEffect(() => {
        const fetchAllProgress = async () => {
            try {
                // First, get all video durations from the videos prop
                const videoDurations = {};
                videos?.forEach(video => {
                    if (video.duration) {
                        videoDurations[video.id] = parseFloat(video.duration);
                    }
                });

                const response = await fetch(`${BASE_URL}/api/course-progress/${courseId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const results = await response.json();
                
                // Create a map of video progress
                const progressMap = {};
                results.forEach(result => {
                    const watched = parseFloat(result.watched_duration) || 0;
                    const duration = parseFloat(result.total_duration) || 0;
                    
                    if (duration > 0) {
                        progressMap[result.video_id] = Math.min(Math.round((watched / duration) * 100), 100);
                    }
                });
                
                setVideoProgress(progressMap);
            } catch (error) {
                console.error('Error fetching video progress:', error);
            }
        };

        if (videos?.length) {
            fetchAllProgress();
        }
    }, [videos, courseId, token]);

    useEffect(() => {
        if (startTime && player) {
           // console.log('Seeking to startTime:', startTime);
            // Add a small delay to ensure player is ready
            const timeoutId = setTimeout(() => {
                player.seekTo(parseFloat(startTime));
                const internalPlayer = player.getInternalPlayer();
                if (video?.videoType === 'youtube') {
                    internalPlayer.playVideo();
                } else {
                    internalPlayer.play().catch(error => {
                        console.error('Error playing video:', error);
                    });
                }
            }, 500);
            
            return () => clearTimeout(timeoutId);
        }
    }, [video?.id, player, startTime]); // Add video.id to dependencies

    useEffect(() => {
        const fetchLastPosition = async () => {
            if (!video?.id) return;
            
            try {
                const response = await fetch(`${BASE_URL}/api/getvideoprogress/${video.id}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const data = await response.json();
                
                if (data.watched_duration) {
                    setLastWatchedPosition(data.watched_duration);
                }
            } catch (error) {
                console.error('Error fetching last watched position:', error);
            }
        };

        fetchLastPosition();
    }, [video?.id, token]);

    const loadThreadAndMessages = async () => {
        try {
            const threadId = await createThread(courseName);
            if (threadId) {
                setLMSThreadId(threadId);
                await fetchMessages(threadId);
            }
        } catch (error) {
            console.error('Error loading thread and messages:', error);
        } finally {
            setIsLoadingMessages(false);
        }
    };

    const createThread = async (course_name) => {
        try {
            const response = await fetch(`${BASE_URL}/api/createLMSthread`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ course_name })
            });
            
            if (!response.ok) throw new Error('Failed to create thread');
            const data = await response.json();
            return data.threadId;
        } catch (error) {
            console.error('Error creating thread:', error);
            return null;
        }
    };

    const fetchMessages = async (threadId) => {
        try {
            const response = await fetch(`${BASE_URL}/api/getLMSmessages/${threadId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });
            
            if (!response.ok) throw new Error('Failed to fetch messages');
            const data = await response.json();
            setMessages(data.messages);
        } catch (error) {
            console.error('Error fetching messages:', error);
        }
    };

    const fetchChatHistory = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/getLMSChatHistory?course_name=${courseName}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });

            if (!response.ok) throw new Error('Failed to fetch chat history');
            const data = await response.json();
            setChatHistory(data.chatHistory);
        } catch (error) {
            console.error('Error fetching chat history:', error);
        }
    };

    const handleThreadSelect = async (threadId) => {
        setLMSThreadId(threadId);
        setIsLoadingMessages(true);
        try {
            await fetchMessages(threadId);
        } catch (error) {
            console.error('Error loading messages:', error);
        } finally {
            setIsLoadingMessages(false);
        }
    };

    const sendMessage = async () => {
        if (!inputMessage.trim() || !lmsThreadId) return;

        const newMessage = { role: 'user', content: inputMessage };
        setMessages(prev => [...prev, newMessage]);
        setInputMessage('');
        setIsLoading(true);

        try {
            await fetch(`${BASE_URL}/api/sendLMSchatmessage`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    threadId: lmsThreadId,
                    message: inputMessage,
                    course_name: courseName,
                    course_id: courseId,
                    video_id: video.id
                }),
            });

            await fetchMessages(lmsThreadId);
            await fetchChatHistory();
        } catch (error) {
            console.error('Error sending message:', error);
            setSnackbarMessage('Error sending message');
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
        } finally {
            setIsLoading(false);
        }
    };

    const onReady = (player) => {
        setPlayer(player);
        let duration;
        let hasInitiallySeeked = false;  // Flag to track if we've already seeked
        
        // Handle duration differently for MP4 and YouTube
        if (video?.videoType === 'youtube') {
            duration = player.getDuration();
        } else {
            duration = player.getInternalPlayer().duration;
        }
        
        // Only seek to startTime if provided (for navigation between videos)
        if (startTime) {
           // console.log('Seeking to startTime:', startTime);
            player.seekTo(parseFloat(startTime));
            
            // Auto-play after seeking for navigation
            const internalPlayer = player.getInternalPlayer();
            if (video?.videoType === 'youtube') {
                internalPlayer.playVideo();
            } else {
                internalPlayer.play().catch(error => {
                    console.error('Error playing video:', error);
                });
            }
        }
        
        // Add event listeners for play
        const internalPlayer = player.getInternalPlayer();
        if (video?.videoType === 'youtube') {
            internalPlayer.addEventListener('onStateChange', (event) => {
                // YouTube state 1 means playing
                if (event.data === 1 && !startTime && lastWatchedPosition > 0 && !hasInitiallySeeked) {
                    hasInitiallySeeked = true;
                    player.seekTo(lastWatchedPosition);
                    internalPlayer.playVideo();
                }
            });
        } else {
            internalPlayer.addEventListener('play', () => {
                // For MP4, check if we should seek to last position
                if (!startTime && lastWatchedPosition > 0 && !hasInitiallySeeked && internalPlayer.currentTime < 1) {
                    hasInitiallySeeked = true;
                    player.seekTo(lastWatchedPosition);
                    internalPlayer.play();
                }
            });
        }
        
        // console.log('Video loaded:', {
        //     type: video?.videoType,
        //     duration,
        //     videoId: video.id,
        //     startTime,
        //     lastWatchedPosition
        // });
        
        if (progressInterval.current) {
            clearInterval(progressInterval.current);
        }

        progressInterval.current = setInterval(() => {
            let currentTime;
            
            // Get current time based on video type
            if (video?.videoType === 'youtube') {
                currentTime = player.getCurrentTime();
            } else {
                currentTime = player.getInternalPlayer().currentTime;
            }
            
            const videoId = video.id;
            
            const lastUpdate = lastUpdateTime.current[videoId] || 0;
            if (currentTime - lastUpdate >= 2) {
                updateVideoProgress(currentTime, videoId, duration);
                lastUpdateTime.current[videoId] = currentTime;
            }
        }, 3000);
    };

    // Add event listeners for MP4 videos
    const onDuration = (duration) => {
       // console.log('Duration updated:', duration);
        if (!video?.videoType || video.videoType !== 'youtube') {
            updateVideoProgress(0, video.id, duration);
        }
    };

    const onProgress = ({ played, playedSeconds }) => {
        if (!video?.videoType || video.videoType !== 'youtube') {
            const videoId = video.id;
            const lastUpdate = lastUpdateTime.current[videoId] || 0;
            
            if (playedSeconds - lastUpdate >= 2) {
                console.log('MP4 progress:', {
                    videoId,
                    playedSeconds,
                    played: played * 100
                });
                updateVideoProgress(playedSeconds, videoId, player?.getInternalPlayer()?.duration);
                lastUpdateTime.current[videoId] = playedSeconds;
            }
        }
    };

    useEffect(() => {
        return () => {
            if (progressInterval.current) {
                clearInterval(progressInterval.current);
            }
        };
    }, []);

    const updateVideoProgress = async (currentTime, videoId, totalDuration) => {
        try {
            const watchedTime = Math.round(currentTime * 100) / 100;
            const duration = Math.round(totalDuration * 100) / 100;

           // console.log('Sending progress update:', {
           //     videoId,
           //     watchedTime,
           //     duration,
           //     userId
           // });

            const response = await fetch(`${BASE_URL}/api/videoprogress`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    userId,
                    videoId,
                    watchedDuration: watchedTime,
                    totalDuration: duration
                }),
            });
            
            const data = await response.json();
            if (data.success) {
                lastSentDurationRef.current[videoId] = watchedTime;
                // console.log('Progress updated successfully:', {
                //     videoId,
                //     watchedTime,
                //     totalProgress: (watchedTime / duration) * 100
                // });
            }
        } catch (error) {
            console.error('Error updating video progress:', error);
        }
    };

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    // const scrollToBottom = () => {
    //     messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    // };

    // useEffect(() => {
    //     scrollToBottom();
    // }, [messages]);

    const renderMessage = (msg, index) => {
        // Helper function to handle timestamp clicks
        const handleTimestampClick = (timestamp, videoTitle) => {
            // Convert timestamp to seconds
            const convertToSeconds = (timestamp) => {
                // Remove any brackets or parentheses
                const cleanTime = timestamp.replace(/[\[\]()]/g, '');
                
                // Split into parts
                const parts = cleanTime.split(':');
                const milliseconds = parts[parts.length - 1].includes('.') 
                    ? parseFloat('0' + parts[parts.length - 1].split('.')[1]) / 1000 
                    : 0;

                if (parts.length === 3) { // HH:MM:SS format
                    const [hours, minutes, seconds] = parts;
                    return parseInt(hours) * 3600 + 
                           parseInt(minutes) * 60 + 
                           parseFloat(seconds.split('.')[0]) +
                           milliseconds;
                } else if (parts.length === 2) { // MM:SS format
                    const [minutes, seconds] = parts;
                    return parseInt(minutes) * 60 + 
                           parseFloat(seconds.split('.')[0]) +
                           milliseconds;
                }
                return parseFloat(parts[0]) + milliseconds;
            };

            const timeInSeconds = convertToSeconds(timestamp);
            
            if (videoTitle) {
                // First, check if it's in "Video XX" format
                const videoNumberMatch = videoTitle.match(/^Video (\d+)$/);
                if (videoNumberMatch) {
                    const videoId = parseInt(videoNumberMatch[1]);
                    const targetVideo = videos?.find(v => v.id === videoId);
                    if (targetVideo && targetVideo.id !== video.id) {
                        // Reset player before navigation
                        setPlayer(null);
                        navigate(`/video-player`, {
                            state: { 
                                video: targetVideo,
                                courseId,
                                courseName,
                                videos,
                                startTime: timeInSeconds,
                                preserveChat: true
                            }
                        });
                        return;
                    }
                }

                // If not "Video XX" format, try to find by title
                const titleMatch = videoTitle.replace(/^.*? - /, '').trim();
                const bestMatch = findBestMatchingVideo(titleMatch);
                
                if (bestMatch && bestMatch.id !== video.id) {
                    // Reset player before navigation
                    setPlayer(null);
                    navigate(`/video-player`, {
                        state: { 
                            video: bestMatch,
                            courseId,
                            courseName,
                            videos,
                            startTime: timeInSeconds,
                            preserveChat: true
                        }
                    });
                    return;
                }
            }
            
            // If no video match or same video, seek in current video
            if (player) {
                player.seekTo(timeInSeconds);
                const internalPlayer = player.getInternalPlayer();
                if (video?.videoType === 'youtube') {
                    internalPlayer.playVideo();
                } else {
                    internalPlayer.play();
                }
            }
        };

        // Update the formatTimestamps function to hide video ID in display
        const formatTimestamps = (text) => {
            // Updated regex to handle both formats:
            // 1. [MM:SS - Video Title]
            // 2. [MM:SS - Video XX]
            const timestampRegex = /[\[\(]((?:\d{1,2}:)?\d{1,2}:\d{1,2}(?:\.\d{1,3})?)(?: - ([^)\]]+))?[\]\)]/g;
            const fileReferenceRegex = /\[([^\]]+?) - Video (\d+)\]/g;
            //console.log('fileReferenceRegex:', fileReferenceRegex);
            // Keep track of last seen file reference to avoid duplicates
            let lastFileRef = null;
            
            // First handle file references
            let processedText = text.replace(fileReferenceRegex, (match, filename, videoId) => {
                console.log('match:', match);
                console.log('filename:', filename);
                console.log('videoId:', videoId);
                
                const currentFileRef = `${filename}-${videoId}`;
                console.log('currentFileRef:', currentFileRef);
                // If this is the same as the last file reference, remove it
                if (currentFileRef === lastFileRef) {
                    return '';
                }
                
                lastFileRef = currentFileRef;
                
                const targetVideo = videos?.find(v => v.id === parseInt(videoId));
                if (targetVideo?.supportingDocuments) {
                    const document = targetVideo.supportingDocuments.find(doc => 
                        doc.name.includes(filename) || doc.path.includes(filename)
                    );
                    if (document) {
                        return `[${document.name}](${BASE_URL}/${document.path})`;
                    }
                }
                return match;
            });

            // Remove any trailing dots after file references
            processedText = processedText.replace(/\s*\.\s*(?=\n|$)/g, '');

            // Then handle timestamps
            const parts = processedText.split(timestampRegex);
            
            return parts.map((part, i) => {
                if (i % 3 === 1) { // Timestamp
                    const timestamp = part;
                    const videoTitle = parts[i + 1];
                    
                    // If it's a direct video title reference (not "Video XX" format)
                    let displayTitle = videoTitle;
                    if (videoTitle) {
                        if (videoTitle.match(/^Video \d+$/)) {
                            // Handle "Video XX" format
                            const videoNum = parseInt(videoTitle.replace('Video ', ''));
                            const targetVideo = videos?.find(v => v.id === videoNum);
                            displayTitle = targetVideo?.title || videoTitle;
                        } else {
                            // For direct title references, try to find the matching video
                            const bestMatch = findBestMatchingVideo(videoTitle);
                            displayTitle = bestMatch?.title || videoTitle;
                        }
                    }

                    return (
                        <Button
                            key={i}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleTimestampClick(timestamp, videoTitle);
                            }}
                            sx={{
                                color: 'primary.main',
                                textTransform: 'none',
                                p: '0 4px',
                                minWidth: 'auto',
                                fontWeight: 'inherit',
                                fontSize: 'inherit',
                                '&:hover': {
                                    backgroundColor: 'action.hover'
                                }
                            }}
                        >
                            {displayTitle ? `(${timestamp} - ${displayTitle})` : `(${timestamp})`}
                        </Button>
                    );
                } else if (i % 3 === 2) { // Video ID/Title - skip it as it's handled above
                    return null;
                }
                
                // Handle any markdown-style links in the text
                const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
                const linkParts = part.split(linkRegex);
                
                return linkParts.map((linkPart, j) => {
                    if (j % 3 === 1) { // Link text
                        return (
                            <Button
                                key={`link-${j}`}
                                component="a"
                                href={linkParts[j + 1]}
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{
                                    color: 'primary.main',
                                    textTransform: 'none',
                                    p: '0 4px',
                                    minWidth: 'auto',
                                    fontWeight: 'inherit',
                                    fontSize: 'inherit',
                                    '&:hover': {
                                        backgroundColor: 'action.hover'
                                    }
                                }}
                            >
                                {linkPart}
                            </Button>
                        );
                    } else if (j % 3 === 2) { // Link URL - skip it
                        return null;
                    }
                    return <span key={`text-${j}`}>{formatBoldText(linkPart)}</span>;
                });
            });
        };

        // Helper function to format text with bold sections
        const formatBoldText = (text) => {
            const parts = text.split(/(\*\*.*?\*\*)/g);
            return parts.map((part, i) => {
                if (part.startsWith('**') && part.endsWith('**')) {
                    return (
                        <Typography
                            key={i}
                            component="span"
                            sx={{ fontWeight: 'bold' }}
                        >
                            {part.slice(2, -2)}
                        </Typography>
                    );
                }
                return <span key={i}>{part}</span>;
            });
        };

        // Helper function to format numbered lists and paragraphs
        const formatContent = (content) => {
            return content.split('\n').map((paragraph, i) => {
                const numberMatch = paragraph.match(/^(\d+\.\s*)(.*)/);
                
                if (numberMatch) {
                    return (
                        <Box key={i} sx={{ display: 'flex', gap: 1, mb: 1 }}>
                            <Typography component="span" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                                {numberMatch[1]}
                            </Typography>
                            <Typography component="span">
                                {formatTimestamps(numberMatch[2])}
                            </Typography>
                        </Box>
                    );
                }
                
                return (
                    <Typography 
                        key={i} 
                        variant="body1" 
                        paragraph={true}
                        sx={{ mb: 1 }}
                    >
                        {formatTimestamps(paragraph)}
                    </Typography>
                );
            });
        };

        return (
            <Box 
                key={index} 
                sx={{ 
                    mb: 2, 
                    p: 2, 
                    backgroundColor: msg.role === 'user' 
                        ? theme.palette.action.selected
                        : theme.palette.background.paper,
                    borderRadius: 2,
                    maxWidth: '80%',
                    width: 'fit-content',
                    alignSelf: msg.role === 'user' ? 'flex-end' : 'flex-start',
                }}
            >
                <Typography 
                    variant="subtitle2" 
                    sx={{ 
                        mb: 0.5,
                        color: msg.role === 'user' ? 'primary.main' : 'secondary.main',
                        fontWeight: 'bold'
                    }}
                >
                    {msg.role === 'user' ? 'You' : 'SAGE'}
                </Typography>
                {formatContent(msg.content)}
            </Box>
        );
    };

    const formatYouTubeUrl = (url) => {
        if (!url) return '';
        
        // Only try to parse if it's a YouTube URL
        if (url && url.includes('youtube.com')) {
            try {
                const urlObj = new URL(url);
                const searchParams = new URLSearchParams(urlObj.search);
                
                // If it's a playlist URL and has a video index
                if (searchParams.has('list') && searchParams.has('v')) {
                    const videoId = searchParams.get('v');
                    const playlistId = searchParams.get('list');
                    const index = searchParams.get('index');
                    
                    // Include both video ID and playlist ID, and maintain the index
                    return `https://www.youtube.com/watch?v=${videoId}&list=${playlistId}&index=${index}`;
                }
                
                return url;
            } catch (error) {
                console.error('Error formatting YouTube URL:', error);
                return url;
            }
        }
        return url;
    };

    // Add this helper function at the component level
    const findBestMatchingVideo = (searchTitle) => {
        if (!searchTitle || !videos) return null;

        const calculateSimilarity = (str1, str2) => {
            const s1 = str1.toLowerCase().replace(/[^a-z0-9]/g, '');
            const s2 = str2.toLowerCase().replace(/[^a-z0-9]/g, '');
            
            let matches = 0;
            const minLen = Math.min(s1.length, s2.length);
            
            for (let i = 0; i < minLen; i++) {
                if (s1[i] === s2[i]) matches++;
            }
            
            return matches / Math.max(s1.length, s2.length);
        };

        let bestMatch = null;
        let bestSimilarity = 0;

        // Clean the search title (remove any timestamp prefix if present)
        const cleanSearchTitle = searchTitle.replace(/^.*? - /, '').trim();

        videos.forEach(v => {
            const similarity = calculateSimilarity(v.title, cleanSearchTitle);
            if (similarity > bestSimilarity && similarity > 0.5) {
                bestSimilarity = similarity;
                bestMatch = v;
            }
        });

        return bestMatch;
    };

    return (
        <Container maxWidth="lg" sx={{ 
            mt: 3,
            mb: 4,
            height: '90vh',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            width: '95%',
            maxWidth: '1600px',
        }}>
            <IconButton 
                onClick={() => {
                    if (courseId) {
                        navigate(`/course-details/${courseId}`, {
                            state: { 
                                courseId,
                                courseName,
                                course: {
                                    id: courseId,
                                    course_name: courseName,
                                    course_description: location.state?.courseDescription,
                                }
                            }
                        });
                    } else {
                        alert('No courseId available');
                        navigate('/');
                    }
                }}
                sx={{ 
                    mb: 0.5,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    transition: 'all 0.2s ease',
                    borderRadius: '8px',
                    padding: '4px 12px',
                    alignSelf: 'flex-start',
                    '&:hover': {
                        backgroundColor: 'action.hover',
                    }
                }}
            >
                <ArrowBackIcon /> 
                <Typography 
                    component="span"
                    sx={{ 
                        fontWeight: 500,
                        color: 'text.primary'
                    }}
                >
                    Back to Course Details
                </Typography>
            </IconButton>

            <Box sx={{ 
                display: 'flex', 
                gap: 2, 
                height: 'calc(100% - 18px)',
                width: '100%'
            }}>
                {/* Video Section */}
                <Box sx={{ 
                    width: '65%',
                    display: 'flex',
                    flexDirection: 'column',
                    height: !video?.supportingDocuments?.length ? '95%' : '100%',
                }}>
                    <Paper sx={{ 
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'hidden',
                        p: 2,
                        height: !video?.supportingDocuments?.length ? '100%' : '100%',
                    }}>
                        <Box sx={{ 
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            mb: 2,
                            backgroundColor: 'background.paper',
                            borderRadius: 1,
                            py: 0.5,
                            px: 1,
                        }}>
                            <IconButton 
                                onClick={() => {
                                    const currentIndex = videos?.findIndex(v => v.id === video?.id);
                                    if (currentIndex > 0) {
                                        navigate(`/video-player`, {
                                            state: { 
                                                video: videos[currentIndex - 1],
                                                courseId,
                                                courseName,
                                                videos,
                                            }
                                        });
                                    }
                                }}
                                disabled={videos?.findIndex(v => v.id === video?.id) === 0}
                                size="small" // Smaller icon button
                                sx={{
                                    '&.Mui-disabled': {
                                        opacity: 0.3,
                                    }
                                }}
                            >
                                <NavigateBeforeIcon fontSize="small" />
                            </IconButton>

                            <Box 
                                sx={{ 
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                    flex: 1,
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                    position: 'relative',
                                    '&:hover': {
                                        '& .video-title': {
                                            color: 'primary.main',
                                        }
                                    }
                                }}
                                onClick={(event) => setVideoMenuAnchor(event.currentTarget)}
                            >
                                <Typography 
                                    variant="body2"
                                    sx={{ 
                                        color: 'primary.main',
                                        fontWeight: 500,
                                    }}
                                >
                                    {`${videos?.findIndex(v => v.id === video?.id) + 1} / ${videos?.length}`}
                                </Typography>
                                <Typography 
                                    variant="body2"
                                    className="video-title"
                                    sx={{ 
                                        maxWidth: '300px',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        textAlign: 'center',
                                        transition: 'color 0.2s',
                                    }}
                                >
                                    {video?.title}
                                </Typography>
                            </Box>

                            <IconButton 
                                onClick={() => {
                                    const currentIndex = videos?.findIndex(v => v.id === video?.id);
                                    if (currentIndex < videos?.length - 1) {
                                        navigate(`/video-player`, {
                                            state: { 
                                                video: videos[currentIndex + 1],
                                                courseId,
                                                courseName,
                                                videos,
                                            }
                                        });
                                    }
                                }}
                                disabled={videos?.findIndex(v => v.id === video?.id) === videos?.length - 1}
                                size="small" // Smaller icon button
                                sx={{
                                    '&.Mui-disabled': {
                                        opacity: 0.3,
                                    }
                                }}
                            >
                                <NavigateNextIcon fontSize="small" />
                            </IconButton>
                        </Box>
                        
                        <Menu
                            anchorEl={videoMenuAnchor}
                            open={Boolean(videoMenuAnchor)}
                            onClose={() => setVideoMenuAnchor(null)}
                            PaperProps={{
                                sx: {
                                    mt: 1,
                                    backgroundColor: 'background.default',
                                    borderRadius: 2,
                                    width: '48.5%',
                                    maxHeight: '276px',
                                    overflowY: 'scroll',
                                    '&::-webkit-scrollbar': {
                                        display: 'none'
                                    },
                                    scrollbarWidth: 'none',
                                    msOverflowStyle: 'none',
                                    '& .MuiList-root': {
                                        p: 1
                                    }
                                }
                            }}
                            transformOrigin={{ horizontal: 'center', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                        >
                            {videos?.map((v, index) => (
                                <MenuItem 
                                    key={v.id}
                                    selected={v.id === video?.id}
                                    onClick={() => {
                                        setVideoMenuAnchor(null);
                                        navigate(`/video-player`, {
                                            state: { 
                                                video: v,
                                                courseId,
                                                courseName,
                                                videos,
                                            }
                                        });
                                    }}
                                    sx={{
                                        borderRadius: 1,
                                        mb: 0.5,
                                        py: 0.75,
                                        px: 2,
                                        position: 'relative',
                                        '&:last-child': {
                                            mb: 0,
                                        },
                                        '&.Mui-selected': {
                                            backgroundColor: 'background.paper',
                                            '&:hover': {
                                                backgroundColor: 'background.paper',
                                            }
                                        },
                                        '&:hover': {
                                            backgroundColor: 'action.hover',
                                        },
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        py: 1,
                                        px: 2,
                                        mr: 2,
                                        position: 'relative',
                                        overflow: 'hidden',
                                        '&::before': {
                                            content: '""',
                                            position: 'absolute',
                                            left: 0,
                                            top: 0,
                                            height: '100%',
                                            width: `${videoProgress[v.id] || 0}%`,
                                            backgroundColor: 'grey.500',
                                            opacity: 0.1,
                                            transition: 'width 0.3s ease'
                                        }
                                    }}
                                >
                                    <Box sx={{ 
                                        display: 'flex', 
                                        alignItems: 'center',
                                        flex: 1,
                                        minWidth: 0,
                                        position: 'relative',
                                        zIndex: 1
                                    }}>
                                        <Typography 
                                            variant="body2"
                                            sx={{ 
                                                color: 'primary.main',
                                                fontWeight: 500,
                                                minWidth: '24px'
                                            }}
                                        >
                                            {index + 1}.
                                        </Typography>
                                        <Typography 
                                            variant="body2"
                                            sx={{ 
                                                ml: 1,
                                                fontWeight: v.id === video?.id ? 500 : 400,
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap'
                                            }}
                                        >
                                            {v.title}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ 
                                        display: 'flex',
                                        alignItems: 'center',
                                        ml: 2,
                                        mr: 1,
                                        position: 'relative',
                                        zIndex: 1
                                    }}>
                                        <Typography 
                                            variant="caption"
                                            sx={{ 
                                                color: theme => videoProgress[v.id] > 0 ? 'primary.main' : 'text.secondary',
                                                opacity: 0.8,
                                                minWidth: '40px',
                                                textAlign: 'right'
                                            }}
                                        >
                                            {`${videoProgress[v.id] || 0}%`}
                                        </Typography>
                                    </Box>
                                </MenuItem>
                            ))}
                        </Menu>

                        <Box sx={{ 
                            position: 'relative',
                            width: '100%',
                            flex: !video?.supportingDocuments?.length ? 1 : 'none', // Take remaining space if no docs
                            minHeight: !video?.supportingDocuments?.length ? 'calc(100% - 48px)' : '86.25%', // Full height minus nav when no docs
                            backgroundColor: 'background.paper',
                            borderRadius: 1,
                            overflow: 'hidden',
                        }}>
                            <ReactPlayer
                                url={video?.videoType === 'youtube' 
                                    ? formatYouTubeUrl(video.url) 
                                    : video?.mp4_url 
                                        ? `${BASE_URL}${video.mp4_url}` 
                                        : ''}
                                controls
                                width="100%"
                                height="100%"
                                playing={Boolean(startTime)}
                                style={{ 
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                }}
                                onReady={onReady}
                                onDuration={onDuration}
                                onProgress={onProgress}
                                progressInterval={1000}
                                config={{
                                    youtube: {
                                        playerVars: { 
                                            modestbranding: 1, 
                                            rel: 0,
                                            index: video?.url ? new URLSearchParams(new URL(video.url).search).get('index') || 1 : 1,
                                            listType: 'playlist',
                                            list: video?.url ? new URLSearchParams(new URL(video.url).search).get('list') : null,
                                            autoplay: 0
                                        }
                                    },
                                    file: {
                                        attributes: { 
                                            controlsList: 'nodownload',
                                            autoPlay: false
                                        },
                                        forceVideo: true,
                                        forceLoad: true,
                                    }
                                }}
                            />
                        </Box>
                    </Paper>

                    {video?.supportingDocuments?.length > 0 && (
                        <Box sx={{ mt: 2 }}>
                            <SupportingDocuments documents={video?.supportingDocuments} />
                        </Box>
                    )}
                </Box>

                {/* Chat Section */}
                <Paper sx={{ 
                    width: '35%',
                    display: 'flex', 
                    flexDirection: 'column',
                    overflow: 'hidden',
                    p: 2,
                    height: !video?.supportingDocuments?.length 
                        ? '95%' // Full height when no documents
                        : video?.supportingDocuments?.length === 1
                            ? 'calc(56.25vw * 0.65 + 110px)' // One document
                            : 'calc(56.25vw * 0.65 + 132px)', // Two or more documents
                }}> 
                    <Button 
                        onClick={() => setShowChatHistory(!showChatHistory)}
                        variant="outlined"
                        sx={{ 
                            mb: 2,
                            width: '100%',
                            borderColor: 'rgba(255, 255, 255, 0.23)',
                            color: '#FFFFFF',
                            '&:hover': {
                                borderColor: 'primary.main',
                                backgroundColor: 'rgba(255, 255, 255, 0.04)',
                            },
                            textTransform: 'none',
                            padding: '8px 16px',
                            '&.Mui-disabled': {
                                borderColor: 'rgba(255, 255, 255, 0.12)',
                                color: 'rgba(255, 255, 255, 0.3)',
                            },
                        }}
                    >
                        {showChatHistory ? 'Hide Chat History' : 'Show Chat History'}
                    </Button>

                    {showChatHistory && (
                        <Box sx={{ mb: 2 }}>
                            <ChatHistoryNavbar 
                                chatHistory={chatHistory}
                                onThreadSelect={handleThreadSelect}
                                currentThreadId={lmsThreadId}
                            />
                        </Box>
                    )}

                    <Box sx={{ 
                        flexGrow: 1, 
                        overflow: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        mb: 2,
                        msOverflowStyle: 'none',
                        scrollbarWidth: 'none',
                        '&::-webkit-scrollbar': {
                            display: 'none'
                        },
                    }}>
                        {isLoadingMessages ? (
                            <Box sx={{ 
                                display: 'flex', 
                                justifyContent: 'center', 
                                alignItems: 'center',
                                height: '100%'
                            }}>
                                <CircularProgress />
                            </Box>
                        ) : messages.length === 0 && !showChatHistory ? (
                            <Box sx={{ 
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                                gap: 2,
                                color: 'text.secondary',
                                textAlign: 'center',
                                p: 3
                            }}>
                                <ChatIcon sx={{ fontSize: 40, opacity: 0.7 }} />
                                <Typography variant="h6" sx={{ fontWeight: 500 }}>
                                    Start a Conversation
                                </Typography>
                                <Typography variant="body2" sx={{ maxWidth: '280px' }}>
                                    Ask questions about the video content, request clarification, or discuss specific topics.
                                </Typography>
                            </Box>
                        ) : (
                            messages.map((msg, index) => renderMessage(msg, index))
                        )}
                        <div ref={messagesEndRef} />
                    </Box>

                    {isLoading && <LinearProgress sx={{ mb: 2 }} />}

                    <Box 
                        component="form" 
                        onSubmit={(e) => { e.preventDefault(); sendMessage(); }}
                        sx={{ 
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            p: 1,
                            borderRadius: 2,
                            backgroundColor: 'background.paper',
                        }}
                    >
                        <TextField
                            fullWidth
                            variant="standard"
                            placeholder="Type your message..."
                            value={inputMessage}
                            onChange={(e) => setInputMessage(e.target.value)}
                            sx={{
                                '& .MuiInput-root': {
                                    padding: '8px 12px',
                                    '&:before, &:after': {
                                        display: 'none',
                                    }
                                },
                            }}
                        />
                        <IconButton 
                            type="submit"
                            disabled={isLoading || !inputMessage.trim()}
                            sx={{
                                backgroundColor: 'primary.main',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: 'primary.dark',
                                },
                                '&.Mui-disabled': {
                                    backgroundColor: 'action.disabledBackground',
                                    color: 'action.disabled',
                                },
                                width: 40,
                                height: 40,
                            }}
                        >
                            <SendIcon />
                        </IconButton>
                    </Box>
                </Paper>
            </Box>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert 
                    onClose={handleSnackbarClose} 
                    severity={snackbarSeverity}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
}

export default VideoPlayer; 