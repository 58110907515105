import React, { useState, useEffect } from 'react';
import {
    Alert,
    Box,
    Button,
    Container,
    CssBaseline,
    Paper,
    Snackbar,
    Typography,
    Chip,
    Toolbar,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    OutlinedInput,
    IconButton,
    Switch,
    FormControlLabel,
} from '@mui/material';
import { BASE_URL } from '../../Constants.js';
import { getToken, getUserId, getRoleId, getClientId } from '../../auth/auth.js';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Pagination from '@mui/material/Pagination';
import { styled, useTheme } from '@mui/material/styles';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const PlaceholderBox = styled(Box)(({ theme }) => ({
    width: 120,
    height: 120,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.mode === 'dark' 
        ? 'rgba(255, 255, 255, 0.05)' 
        : 'rgba(0, 0, 0, 0.03)',
    borderRadius: theme.shape.borderRadius,
}));

function LMSCourseList() {
    const [courses, setCourses] = useState([]);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [editingCourse, setEditingCourse] = useState(null);
    const coursesPerPage = 5;
    
    // Edit form states
    const [courseName, setCourseName] = useState('');
    const [courseDescription, setCourseDescription] = useState('');
    const [isPublic, setIsPublic] = useState(true);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [availableUsers, setAvailableUsers] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [selectedClients, setSelectedClients] = useState([]);
    const [clients, setClients] = useState([]);
    const [newImage, setNewImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [sendEmailNotification, setSendEmailNotification] = useState(false);
    
    const isSuperAdmin = getRoleId() === 1;
    const currentClientId = getClientId();

    const theme = useTheme();

    // Fetch courses
    const fetchCourses = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/courses`, {
                headers: {
                    'Authorization': `Bearer ${getToken()}`
                }
            });
            if (!response.ok) throw new Error('Failed to fetch courses');
            const data = await response.json();
            setCourses(data);
        } catch (error) {
            console.error('Error fetching courses:', error);
            setError('Failed to fetch courses');
        }
    };

    // Handle edit dialog open
    const handleEdit = (course) => {
        setEditingCourse(course);
        setCourseName(course.course_name);
        setCourseDescription(course.course_description || '');
        setIsPublic(course.is_public);
        setSelectedRoles(Array.isArray(course.access_roles) ? course.access_roles : []);
        setSelectedClients(Array.isArray(course.access_clients) ? course.access_clients : []);
        setSelectedUsers(Array.isArray(course.assigned_users) ? 
            course.assigned_users.map(user => user.user_id) : []);
        setSendEmailNotification(false);
        
        // Set image preview if course has an image
        if (course.image_path) {
            setImagePreview(`${BASE_URL}/uploads${course.image_path}`);
        } else {
            setImagePreview(null);
        }
        
        setEditDialogOpen(true);
        fetchAvailableUsers();
    };

    // Handle course update
    const handleUpdate = async () => {
        setIsLoading(true);
        try {
            const formData = new FormData();
            formData.append('course_name', courseName.trim());
            formData.append('course_description', courseDescription.trim());
            formData.append('is_public', isPublic ? '1' : '0');
            formData.append('assigned_users', JSON.stringify(selectedUsers));
            formData.append('send_email', sendEmailNotification ? '1' : '0');
            formData.append('access_control', JSON.stringify({
                roles: selectedRoles || [],
                clients: isSuperAdmin ? (selectedClients || []) : [currentClientId]
            }));

            // Append new image if selected
            if (newImage) {
                formData.append('course_image', newImage);
            }

            const response = await fetch(`${BASE_URL}/api/courses/${editingCourse.id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${getToken()}`
                },
                body: formData
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to update course');
            }

            setSuccessMessage('Course updated successfully!');
            setSuccess(true);
            setEditDialogOpen(false);
            fetchCourses();
            
            // Reset image states
            setNewImage(null);
            setImagePreview(null);
        } catch (error) {
            console.error('Error updating course:', error);
            setError(error.message || 'Failed to update course');
        } finally {
            setIsLoading(false);
        }
    };

    // Handle delete
    const handleDelete = async (courseId) => {
        if (!window.confirm('Are you sure you want to delete this course?')) return;

        try {
            const response = await fetch(`${BASE_URL}/api/courses/${courseId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${getToken()}`
                }
            });

            if (!response.ok) throw new Error('Failed to delete course');
            
            setSuccessMessage('Course deleted successfully!');
            setSuccess(true);
            fetchCourses();
        } catch (error) {
            setError('Failed to delete course');
        }
    };

    // Fetch available users for private courses
    const fetchAvailableUsers = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/readadmins`, {
                headers: {
                    'Authorization': `Bearer ${getToken()}`
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch users');
            }
            const data = await response.json();
            setAvailableUsers(data);
        } catch (error) {
            console.error('Error fetching users:', error);
            setError('Failed to fetch available users');
        }
    };

    // Add image handling functions
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setNewImage(file);
            setImagePreview(URL.createObjectURL(file));
        }
    };

    const handleRemoveImage = () => {
        setNewImage(null);
        setImagePreview(null);
    };

    useEffect(() => {
        fetchCourses();
        if (isSuperAdmin) {
            // Fetch clients for super admin
            fetch(`${BASE_URL}/api/readclients`, {
                headers: {
                    'Authorization': `Bearer ${getToken()}`
                }
            })
            .then(response => response.json())
            .then(data => {
                if (Array.isArray(data)) {
                    setClients(data);
                }
            })
            .catch(error => console.error('Error fetching clients:', error));
        }
    }, []);

    const indexOfLastCourse = page * coursesPerPage;
    const indexOfFirstCourse = indexOfLastCourse - coursesPerPage;
    const currentCourses = courses.slice(indexOfFirstCourse, indexOfLastCourse);
    const renderRoles = (selected) => {
        if (!Array.isArray(selected)) {
            selected = [];
        }
        return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                    <Chip 
                        key={value} 
                        label={value === 1 ? 'Super Admin' : value === 2 ? 'Admin' : 'User'} 
                    />
                ))}
            </Box>
        );
    };

    const renderClients = (selected) => {
        if (!Array.isArray(selected)) {
            selected = [];
        }
        return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((clientId) => (
                    <Chip 
                        key={clientId} 
                        label={clients.find(c => c.client_id === clientId)?.client_name || clientId} 
                    />
                ))}
            </Box>
        );
    };

    const renderSelectedUsers = (selected) => (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((userId) => {
                const user = availableUsers.find(u => u.user_id === userId);
                return user ? (
                    <Chip
                        key={userId}
                        label={`${user.first_name} ${user.last_name} (${user.email})`}
                        size="small"
                    />
                ) : null;
            })}
        </Box>
    );

    return (
        <Box sx={{ backgroundColor: 'background.default', flexGrow: 1, minHeight: '100vh', overflow: 'auto' }}>
            <CssBaseline />

            <Container sx={{ mt: 4, mb: 4, maxWidth: '1000px !important' }}>
                <Typography variant="h4" gutterBottom>
                    Course Management
                </Typography>

                {/* Course List */}
                <Paper sx={{ p: 4 }}>
                    {currentCourses.map((course) => (
                        <Box key={course.id} sx={{ 
                            mb: 2, 
                            p: 2, 
                            border: '1px solid #e0e0e0', 
                            borderRadius: 1,
                            display: 'flex',
                            gap: 2,
                        }}>
                            {/* Course Image Container with fixed dimensions */}
                            <Box sx={{ 
                                flexShrink: 0, // Prevent image from shrinking
                                width: 120,    // Fixed width
                                height: 120    // Fixed height
                            }}>
                                {course.image_path ? (
                                    <Box
                                        component="img"
                                        src={`${BASE_URL}/uploads${course.image_path}`}
                                        alt={course.course_name}
                                        onError={(e) => {
                                            e.target.style.display = 'none';
                                            e.target.parentElement.appendChild(
                                                document.createElement('div')
                                            ).innerHTML = `
                                                <div style="width: 120px; 
                                                   height: 120px; 
                                                   display: flex; 
                                                   align-items: center; 
                                                   justify-content: center; 
                                                   background-color: ${theme.palette.mode === 'dark' 
                                                       ? 'rgba(255, 255, 255, 0.05)' 
                                                       : 'rgba(0, 0, 0, 0.03)'};
                                                   border-radius: ${theme.shape.borderRadius}px;">
                                                    <svg style="width: 48px; height: 48px; opacity: 0.5;" viewBox="0 0 24 24">
                                                        <path fill="currentColor" d="M21,5C19.89,4.65 18.67,4.5 17.5,4.5C15.55,4.5 13.45,4.9 12,6C10.55,4.9 8.45,4.5 6.5,4.5C4.55,4.5 2.45,4.9 1,6V20.65C1,20.9 1.25,21.15 1.5,21.15C1.6,21.15 1.65,21.1 1.75,21.1C3.1,20.45 5.05,20 6.5,20C8.45,20 10.55,20.4 12,21.5C13.35,20.65 15.8,20 17.5,20C19.15,20 20.85,20.3 22.25,21.05C22.35,21.1 22.4,21.1 22.5,21.1C22.75,21.1 23,20.85 23,20.6V6C22.4,5.55 21.75,5.25 21,5M21,18.5C19.9,18.15 18.7,18 17.5,18C15.8,18 13.35,18.65 12,19.5V8C13.35,7.15 15.8,6.5 17.5,6.5C18.7,6.5 19.9,6.65 21,7V18.5Z" />
                                                    </svg>
                                                </div>`;
                                        }}
                                        sx={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover',
                                            borderRadius: 1,
                                            backgroundColor: theme => theme.palette.mode === 'dark' 
                                                ? 'rgba(255, 255, 255, 0.05)' 
                                                : 'rgba(0, 0, 0, 0.03)',
                                        }}
                                    />
                                ) : (
                                    <PlaceholderBox>
                                        <AutoStoriesIcon 
                                            sx={{ 
                                                width: 48, 
                                                height: 48, 
                                                opacity: 0.5,
                                                color: 'text.secondary'
                                            }} 
                                        />
                                    </PlaceholderBox>
                                )}
                            </Box>

                            {/* Course Details with proper text wrapping */}
                            <Box sx={{ 
                                flexGrow: 1,
                                display: 'flex',
                                justifyContent: 'space-between',
                                minWidth: 0 // Enable text truncation in child elements
                            }}>
                                <Box sx={{ 
                                    flexGrow: 1,
                                    minWidth: 0 // Enable text truncation
                                }}>
                                    <Typography variant="h6" noWrap>
                                        {course.course_name}
                                    </Typography>
                                    <Typography 
                                        variant="body2" 
                                        color="text.secondary"
                                        sx={{ 
                                            mt: 1,
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            display: '-webkit-box',
                                            WebkitLineClamp: 3,
                                            WebkitBoxOrient: 'vertical'
                                        }}
                                    >
                                        {course.course_description}
                                    </Typography>
                                    <Box sx={{ mt: 1 }}>
                                        <Chip 
                                            label={course.is_public ? "Public" : "Private"} 
                                            size="small" 
                                            color={course.is_public ? "success" : "default"}
                                            sx={{ mr: 1 }}
                                        />
                                    </Box>
                                </Box>

                                {/* Action Buttons */}
                                <Box sx={{ 
                                    display: 'flex', 
                                    flexDirection: 'row',
                                    gap: 1,
                                    ml: 2,
                                    flexShrink: 0, // Prevent buttons from shrinking
                                    alignItems: 'flex-start' // Align buttons to the top
                                }}>
                                    <IconButton
                                        size="small"
                                        onClick={() => handleEdit(course)}
                                        sx={{ 
                                            color: 'primary.main',
                                            '&:hover': {
                                                backgroundColor: 'rgba(123, 79, 255, 0.08)' // Subtle hover effect
                                            }
                                        }}
                                    >
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                                    <IconButton
                                        size="small"
                                        onClick={() => handleDelete(course.id)}
                                        sx={{ 
                                            color: 'error.main',
                                            '&:hover': {
                                                backgroundColor: 'rgba(211, 47, 47, 0.08)' // Subtle hover effect
                                            }
                                        }}
                                    >
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Box>
                    ))}
                    
                    {/* Pagination */}
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                        <Pagination
                            count={Math.ceil(courses.length / coursesPerPage)}
                            page={page}
                            onChange={(event, value) => setPage(value)}
                            color="primary"
                        />
                    </Box>
                </Paper>

                {/* Edit Dialog */}
                <Dialog
                    open={editDialogOpen}
                    onClose={() => setEditDialogOpen(false)}
                    maxWidth="md"
                    fullWidth
                    sx={{
                        '& .MuiDialog-paper': {
                            margin: 0,
                            position: 'fixed',
                            top: '50%',
                            left: '58%',
                            transform: 'translate(-50%, -50%)',
                        }
                    }}
                    BackdropProps={{
                        style: { position: 'fixed' }
                    }}
                >
                    <DialogTitle>Edit Course</DialogTitle>
                    <DialogContent
                        sx={{
                            overflowY: 'auto',
                            maxHeight: 'calc(100vh - 200px)', // Adjust height as needed
                            '&::-webkit-scrollbar': {
                                width: '8px',
                            },
                            '&::-webkit-scrollbar-track': {
                                background: 'transparent',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                background: 'rgba(255, 255, 255, 0.2)',
                                borderRadius: '4px',
                            },
                            '&::-webkit-scrollbar-thumb:hover': {
                                background: 'rgba(255, 255, 255, 0.3)',
                            },
                        }}
                    >
                        {/* Image Section */}
                        <Box sx={{ mt: 2, mb: 2 }}>
                            {imagePreview ? (
                                <Box sx={{ position: 'relative', display: 'inline-block' }}>
                                    <img 
                                        src={imagePreview} 
                                        alt="Course preview" 
                                        style={{ 
                                            maxWidth: '100%', 
                                            maxHeight: '200px',
                                            borderRadius: '8px'
                                        }} 
                                    />
                                    <IconButton
                                        onClick={handleRemoveImage}
                                        sx={{
                                            position: 'absolute',
                                            top: 8,
                                            right: 8,
                                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, 0.7)'
                                            }
                                        }}
                                    >
                                        <DeleteIcon sx={{ color: 'white' }} />
                                    </IconButton>
                                </Box>
                            ) : (
                                <Button
                                    component="label"
                                    variant="outlined"
                                    startIcon={<CloudUploadIcon />}
                                    sx={{ width: '100%', height: '100px' }}
                                >
                                    Upload Course Image
                                    <input
                                        type="file"
                                        hidden
                                        accept="image/*"
                                        onChange={handleImageChange}
                                    />
                                </Button>
                            )}
                        </Box>

                        {/* Existing form fields */}
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label="Course Name"
                            value={courseName}
                            onChange={(e) => setCourseName(e.target.value)}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label="Course Description"
                            multiline
                            rows={4}
                            value={courseDescription}
                            onChange={(e) => setCourseDescription(e.target.value)}
                        />
                        
                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <InputLabel>Role Access</InputLabel>
                            <Select
                                multiple
                                value={selectedRoles}
                                onChange={(e) => setSelectedRoles(e.target.value)}
                                input={<OutlinedInput label="Role Access" />}
                                renderValue={renderRoles}
                            >
                                {isSuperAdmin && (
                                    <MenuItem value={1}>Super Admin</MenuItem>
                                )}
                                <MenuItem value={2}>Admin</MenuItem>
                                <MenuItem value={3}>User</MenuItem>
                            </Select>
                        </FormControl>

                        {isSuperAdmin && (
                            <FormControl fullWidth sx={{ mt: 2 }}>
                                <InputLabel>Client Access</InputLabel>
                                <Select
                                    multiple
                                    value={selectedClients}
                                    onChange={(e) => setSelectedClients(e.target.value)}
                                    input={<OutlinedInput label="Client Access" />}
                                    renderValue={renderClients}
                                >
                                    {clients.map((client) => (
                                        <MenuItem key={client.client_id} value={client.client_id}>
                                            {client.client_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}

                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <InputLabel>Course Access Type</InputLabel>
                            <Select
                                value={isPublic}
                                onChange={(e) => {
                                    setIsPublic(e.target.value);
                                    if (e.target.value) {
                                        setSelectedUsers([]); // Clear selected users when switching to public
                                    }
                                }}
                                label="Course Access Type"
                            >
                                <MenuItem value={true}>Public</MenuItem>
                                <MenuItem value={false}>Private</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <InputLabel>Assign Students</InputLabel>
                            <Select
                                multiple
                                value={selectedUsers}
                                onChange={(e) => setSelectedUsers(e.target.value)}
                                input={<OutlinedInput label="Assign Students" />}
                                renderValue={renderSelectedUsers}
                            >
                                {availableUsers.map((user) => (
                                    <MenuItem key={user.user_id} value={user.user_id}>
                                        {`${user.first_name} ${user.last_name} (${user.email})`}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControlLabel
                            control={
                                <Switch
                                    checked={sendEmailNotification}
                                    onChange={(e) => setSendEmailNotification(e.target.checked)}
                                    name="sendEmailNotification"
                                    color="primary"
                                />
                            }
                            label="Send email notification to users"
                            sx={{ mt: 2, mb: 2, display: 'block' }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setEditDialogOpen(false)}>Cancel</Button>
                        <Button onClick={handleUpdate} variant="contained" disabled={isLoading}>
                            {isLoading ? 'Updating...' : 'Update'}
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Snackbars */}
                <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
                    <Alert onClose={() => setError('')} severity="error">
                        {error}
                    </Alert>
                </Snackbar>
                <Snackbar open={success} autoHideDuration={6000} onClose={() => setSuccess(false)}>
                    <Alert onClose={() => setSuccess(false)} severity="success">
                        {successMessage}
                    </Alert>
                </Snackbar>
            </Container>
        </Box>
    );
}

export default LMSCourseList; 