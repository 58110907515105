import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CircularProgress, Container, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { BASE_URL } from '../Constants';

export default function EmailLogin() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const token = searchParams.get('token');
    if (!token) {
      toast.error('Invalid login link');
      navigate('/login');
      return;
    }

    const verifyToken = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/verify-email-token`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ token })
        });

        if (response.ok) {
          const data = await response.json();
          
          // Store all user data
          sessionStorage.setItem('token', data.token);
          sessionStorage.setItem('user', JSON.stringify(data.user));
          sessionStorage.setItem('role_id', data.user.role_id);
          sessionStorage.setItem('client_id', data.user.client_id);
          
          toast.success('Successfully logged in!');
          
          // Force a page reload to ensure all permissions are properly initialized
          window.location.href = '/';
        } else {
          throw new Error('Invalid or expired link');
        }
      } catch (error) {
        console.error('Token verification failed:', error);
        toast.error(error.message || 'Login failed');
        navigate('/login');
      }
    };

    verifyToken();
  }, [navigate, searchParams]);

  return (
    <Container>
      <Typography variant="h6" align="center" sx={{ mt: 4 }}>
        Verifying your login...
      </Typography>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
        <CircularProgress />
      </div>
    </Container>
  );
} 