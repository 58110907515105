import React, { useState, useEffect } from 'react';
import { 
    Alert,
    Box,
    Button, 
    Container, 
    CssBaseline, 
    Paper, 
    Snackbar, 
    TextField, 
    Toolbar, 
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Chip,
    OutlinedInput,
    IconButton,
} from '@mui/material';
import { BASE_URL } from '../../Constants.js';
import { getToken, getUserId, getRoleId, getClientId } from '../../auth/auth.js';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Pagination from '@mui/material/Pagination';
import CheckIcon from '@mui/icons-material/Check';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

function LMSAddCourse() {
    const [courseName, setCourseName] = useState('');
    const [courseDescription, setCourseDescription] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [editingCourseId, setEditingCourseId] = useState(null);
    const [courses, setCourses] = useState([]);
    const [page, setPage] = useState(1);
    const coursesPerPage = 3;
    const navigate = useNavigate();
    const [successMessage, setSuccessMessage] = useState('');
    const [clients, setClients] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [selectedClients, setSelectedClients] = useState([]);
    const isSuperAdmin = getRoleId() === 1;
    const currentClientId = getClientId();
    const [isPublic, setIsPublic] = useState(true);
    const [availableUsers, setAvailableUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [courseImage, setCourseImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);

    const fetchCourses = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/courses`, {
                headers: {
                    'Authorization': `Bearer ${getToken()}`
                }
            });
            if (!response.ok) throw new Error('Failed to fetch courses');
            const data = await response.json();
            setCourses(data);
        } catch (error) {
            console.error('Error fetching courses:', error);
            setError('Failed to fetch courses');
        }
    };

    const handleEdit = (course) => {
        setEditingCourseId(course.id);
        setCourseName(course.course_name);
        setCourseDescription(course.course_description || '');
        setIsPublic(course.is_public);
        
        // Set assigned users if course is private
        if (!course.is_public && course.assigned_users) {
            // Extract just the user_ids from the assigned_users objects
            const userIds = course.assigned_users.map(user => user.user_id);
            setSelectedUsers(userIds);
        } else {
            setSelectedUsers([]);
        }
        
        // Handle roles
        const roles = Array.isArray(course.access_roles) 
            ? course.access_roles 
            : course.access_roles 
                ? [Number(course.access_roles)] 
                : [];
        setSelectedRoles(roles);
        
        // Handle clients
        const clients = Array.isArray(course.access_clients) 
            ? course.access_clients 
            : course.access_clients 
                ? [Number(course.access_clients)] 
                : [];
        setSelectedClients(clients);

        // If course is private, fetch available users
        if (!course.is_public) {
            fetch(`${BASE_URL}/api/readadmins`, {
                headers: {
                    'Authorization': `Bearer ${getToken()}`
                }
            })
            .then(response => response.json())
            .then(data => {
                setAvailableUsers(data);
            })
            .catch(error => console.error('Error fetching admins:', error));
        }

        if (course.image_path) {
            setImagePreview(`${BASE_URL}${course.image_path}`);
        } else {
            setImagePreview(null);
        }
    };

    const handleDelete = async (courseId) => {
        if (!window.confirm('Are you sure you want to delete this course?')) return;

        try {
            const response = await fetch(`${BASE_URL}/api/courses/${courseId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${getToken()}`
                }
            });

            if (!response.ok) throw new Error('Failed to delete course');
            
            setSuccess(true);
            setSuccessMessage('Course deleted successfully!');
            fetchCourses();
        } catch (error) {
            console.error('Error deleting course:', error);
            setError('Failed to delete course');
        }
    };

    const handleRoleChange = (event) => {
        setSelectedRoles(event.target.value);
    };

    const handleClientChange = (event) => {
        setSelectedClients(event.target.value);
    };

    const handleCourseSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        setError('');
        setSuccess(false);

        try {
            const formData = new FormData();
            formData.append('course_name', courseName);
            formData.append('course_description', courseDescription);
            formData.append('who_created', getUserId());
            formData.append('date_created', new Date().toISOString().split('T')[0]);
            formData.append('is_public', isPublic ? '1' : '0');
            
            // Add access control data
            formData.append('access_control', JSON.stringify({
                roles: selectedRoles,
                clients: selectedClients
            }));

            // Only append assigned users if the course is private
            if (selectedUsers.length > 0) {
                formData.append('assigned_users', JSON.stringify(selectedUsers));
            }

            // Append course image if exists
            if (courseImage) {
                formData.append('course_image', courseImage);
            }

            const url = editingCourseId 
                ? `${BASE_URL}/api/courses/${editingCourseId}`
                : `${BASE_URL}/api/submitcourses`;

            const method = editingCourseId ? 'PUT' : 'POST';

            const response = await fetch(url, {
                method: method,
                headers: {
                    'Authorization': `Bearer ${getToken()}`
                },
                body: formData
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to submit course');
            }

            setSuccess(true);
            setSuccessMessage(editingCourseId ? 'Course updated successfully!' : 'Course added successfully!');
            
            // Reset form
            setCourseName('');
            setCourseDescription('');
            setSelectedRoles([]);
            setEditingCourseId(null);
            setIsPublic(true);
            setSelectedUsers([]);
            setSelectedClients([]);
            setCourseImage(null);
            setImagePreview(null);
            
            // Refresh the courses list
            fetchCourses();

        } catch (error) {
            console.error('Error:', error);
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchCourses();
    }, []);

    useEffect(() => {
        if (isSuperAdmin) {
            fetch(`${BASE_URL}/api/readclients`, {
                headers: {
                    'Authorization': `Bearer ${getToken()}`
                }
            })
            .then(response => response.json())
            .then(data => {
                if (Array.isArray(data)) {
                    setClients(data);
                }
            })
            .catch(error => console.error('Error fetching clients:', error));
        }
    }, [isSuperAdmin]);

    useEffect(() => {
        fetch(`${BASE_URL}/api/readadmins`, {
            headers: {
                'Authorization': `Bearer ${getToken()}`
            }
        })
        .then(response => response.json())
        .then(data => {
            setAvailableUsers(Array.isArray(data) ? data : []);
        })
        .catch(error => {
            console.error('Error fetching users:', error);
            setAvailableUsers([]);
        });
    }, []);

    const buttonText = isLoading 
        ? 'Processing...' 
        : editingCourseId 
            ? 'Update Course' 
            : 'Add Course';

    const formTitle = editingCourseId ? 'Edit Course' : 'Add New Course';

    const handleCancelEdit = () => {
        setEditingCourseId(null);
        setCourseName('');
        setCourseDescription('');
        setSelectedRoles([]);
        setSelectedClients([]);
        setIsPublic(true);
        setSelectedUsers([]);
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const indexOfLastCourse = page * coursesPerPage;
    const indexOfFirstCourse = indexOfLastCourse - coursesPerPage;
    const currentCourses = courses.slice(indexOfFirstCourse, indexOfLastCourse);

    const renderRoles = (selected) => {
        if (!Array.isArray(selected)) {
            selected = [];
        }
        return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                    <Chip 
                        key={value} 
                        label={value === 1 ? 'Super Admin' : value === 2 ? 'Admin' : 'User'} 
                    />
                ))}
            </Box>
        );
    };

    const renderClients = (selected) => {
        if (!Array.isArray(selected)) {
            selected = [];
        }
        return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((clientId) => (
                    <Chip 
                        key={clientId} 
                        label={clients.find(c => c.client_id === clientId)?.client_name || clientId} 
                    />
                ))}
            </Box>
        );
    };

    const renderSelectedUsers = (selected) => (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((userId) => {
                const user = availableUsers.find(u => u.user_id === userId);
                return user ? (
                    <Chip
                        key={userId}
                        label={`${user.first_name} ${user.last_name} (${user.email})`}
                        size="small"
                    />
                ) : null;
            })}
        </Box>
    );

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setCourseImage(file);
            setImagePreview(URL.createObjectURL(file));
        }
    };

    const handleImageDelete = async (courseId) => {
        try {
            const response = await fetch(`${BASE_URL}/api/courses/${courseId}/image`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${getToken()}`
                }
            });

            if (!response.ok) throw new Error('Failed to delete image');

            setCourseImage(null);
            setImagePreview(null);
            setSuccess(true);
            setSuccessMessage('Course image deleted successfully!');
        } catch (error) {
            console.error('Error deleting image:', error);
            setError('Failed to delete course image');
        }
    };

    return (
        <Box 
            sx={{
                backgroundColor: 'background.default',
                flexGrow: 1,
                minHeight: '100vh',
                overflow: 'auto',
            }}
        >
            <CssBaseline />
            <Toolbar />
            <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
                <Paper sx={{ p: 4, mb: 4 }}>
                    <Typography variant="h4" gutterBottom>
                        {formTitle}
                    </Typography>

                    <Box component="form" onSubmit={handleCourseSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="courseName"
                            label="Course Name"
                            name="courseName"
                            autoFocus
                            value={courseName}
                            onChange={(e) => setCourseName(e.target.value)}
                        />

                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="courseDescription"
                            label="Course Description"
                            name="courseDescription"
                            multiline
                            rows={4}
                            value={courseDescription}
                            onChange={(e) => setCourseDescription(e.target.value)}
                        />

                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <InputLabel id="role-access-label">Role Access</InputLabel>
                            <Select
                                labelId="role-access-label"
                                multiple
                                value={selectedRoles || []}
                                onChange={handleRoleChange}
                                input={<OutlinedInput label="Role Access" />}
                                renderValue={renderRoles}
                            >
                                {isSuperAdmin && (
                                    <MenuItem value={1}>Super Admin</MenuItem>
                                )}
                                <MenuItem value={2}>Admin</MenuItem>
                                <MenuItem value={3}>User</MenuItem>
                            </Select>
                        </FormControl>

                        {isSuperAdmin && (
                            <FormControl fullWidth sx={{ mt: 2 }}>
                                <InputLabel id="client-access-label">Client Access</InputLabel>
                                <Select
                                    labelId="client-access-label"
                                    multiple
                                    value={selectedClients || []}
                                    onChange={handleClientChange}
                                    input={<OutlinedInput label="Client Access" />}
                                    renderValue={renderClients}
                                >
                                    {clients.map((client) => (
                                        <MenuItem key={client.client_id} value={client.client_id}>
                                            {client.client_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}

                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <InputLabel>Course Access Type</InputLabel>
                            <Select
                                value={isPublic}
                                onChange={(e) => {
                                    setIsPublic(e.target.value);
                                    if (e.target.value) {
                                        setSelectedUsers([]); // Clear selected users when switching to public
                                    }
                                }}
                                label="Course Access Type"
                            >
                                <MenuItem value={true}>Public</MenuItem>
                                <MenuItem value={false}>Private</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <InputLabel>Assign Students</InputLabel>
                            <Select
                                multiple
                                value={selectedUsers}
                                onChange={(e) => setSelectedUsers(e.target.value)}
                                input={<OutlinedInput label="Assign Students" />}
                                renderValue={renderSelectedUsers}
                            >
                                {Array.isArray(availableUsers) && availableUsers.map((user) => (
                                    <MenuItem key={user.user_id} value={user.user_id}>
                                        {`${user.first_name} ${user.last_name} (${user.email})`}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <Box sx={{ mt: 2, mb: 2 }}>
                            <input
                                accept="image/*"
                                style={{ display: 'none' }}
                                id="course-image-upload"
                                type="file"
                                onChange={handleImageChange}
                            />
                            <label htmlFor="course-image-upload">
                                <Button
                                    variant="outlined"
                                    component="span"
                                    startIcon={<CloudUploadIcon />}
                                    fullWidth
                                >
                                    Upload Course Image
                                </Button>
                            </label>
                            {imagePreview && (
                                <Box sx={{ mt: 2, position: 'relative' }}>
                                    <img
                                        src={imagePreview}
                                        alt="Course preview"
                                        style={{
                                            width: '100%',
                                            maxHeight: '200px',
                                            objectFit: 'cover',
                                            borderRadius: '4px'
                                        }}
                                    />
                                    <IconButton
                                        sx={{
                                            position: 'absolute',
                                            top: 8,
                                            right: 8,
                                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                            },
                                        }}
                                        onClick={() => {
                                            if (editingCourseId) {
                                                handleImageDelete(editingCourseId);
                                            } else {
                                                setCourseImage(null);
                                                setImagePreview(null);
                                            }
                                        }}
                                    >
                                        <DeleteIcon sx={{ color: 'white' }} />
                                    </IconButton>
                                </Box>
                            )}
                        </Box>

                        <Box sx={{ mt: 3, display: 'flex', gap: 2 }}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                disabled={isLoading}
                            >
                                {buttonText}
                            </Button>
                            
                            {editingCourseId && (
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    onClick={handleCancelEdit}
                                    disabled={isLoading}
                                >
                                    Cancel Edit
                                </Button>
                            )}
                        </Box>
                    </Box>
                </Paper>

                {/* Commenting out Existing Courses section
                <Paper sx={{ p: 4 }}>
                    <Typography variant="h4" gutterBottom>
                        Existing Courses
                    </Typography>
                    {currentCourses.map(course => (
                        <Box 
                            key={course.id} 
                            sx={{ 
                                mb: 3, 
                                p: 3, 
                                border: '1px solid #ddd', 
                                borderRadius: 2,
                                backgroundColor: (theme) => theme.palette.background.paper,
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                                <Typography variant="h6">
                                    {course.course_name}
                                </Typography>
                                <Chip 
                                    label={course.is_public ? "Public" : "Private"}
                                    size="small"
                                    color={course.is_public ? "success" : "default"}
                                />
                            </Box>
                            <Typography 
                                variant="body1" 
                                color="text.secondary" 
                                sx={{ mb: 2 }}
                            >
                                {course.course_description}
                            </Typography>
                            
                            {!course.is_public && course.assigned_users?.length > 0 && (
                                <Box sx={{ mb: 2 }}>
                                    <Typography variant="subtitle2" gutterBottom>
                                        Assigned Users:
                                    </Typography>
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                        {course.assigned_users.map((user) => (
                                            <Chip
                                                key={user.user_id}
                                                label={`${user.first_name} ${user.last_name} (${user.email})`}
                                                size="small"
                                                sx={{ maxWidth: '100%' }}
                                            />
                                        ))}
                                    </Box>
                                </Box>
                            )}
                            
                            <Box sx={{ display: 'flex', gap: 2 }}>
                                <Button 
                                    onClick={() => handleEdit(course)} 
                                    variant="outlined" 
                                    startIcon={<EditIcon />}
                                    disabled={isLoading}
                                >
                                    Edit
                                </Button>
                                <Button 
                                    onClick={() => handleDelete(course.id)} 
                                    variant="outlined" 
                                    color="error"
                                    startIcon={<DeleteIcon />}
                                    disabled={isLoading}
                                >
                                    Delete
                                </Button>
                            </Box>
                        </Box>
                    ))}
                    
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                        <Pagination 
                            count={Math.ceil(courses.length / coursesPerPage)}
                            page={page}
                            onChange={handlePageChange}
                            color="primary"
                        />
                    </Box>
                </Paper>
                */}
            </Container>

            <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
                <Alert onClose={() => setError('')} severity="error" sx={{ width: '100%' }}>
                    {error}
                </Alert>
            </Snackbar>

            <Snackbar open={success} autoHideDuration={6000} onClose={() => setSuccess(false)}>
                <Alert onClose={() => setSuccess(false)} severity="success" sx={{ width: '100%' }}>
                    {successMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default LMSAddCourse;
