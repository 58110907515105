import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { 
    Box, Container, Typography, Paper, List, ListItem, 
    ListItemText, IconButton, Divider, LinearProgress,
    CssBaseline
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { getToken, getUserId, getRoleId, isSuperAdmin, getClientId } from '../auth/auth.js';
import { BASE_URL } from '../Constants';
import AIChat from '../components/AIChat';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import styled from '@emotion/styled';
import { useTheme } from '@mui/material/styles';

function CourseDetails() {
    const location = useLocation();
    const navigate = useNavigate();
    const { course, courseId, courseName } = location.state || {};
    const [videos, setVideos] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [videoProgress, setVideoProgress] = useState({});
    const [userProgress, setUserProgress] = useState({});
    const [enrolledUsers, setEnrolledUsers] = useState([]);
    const token = getToken();
    const theme = useTheme();

    useEffect(() => {
        if (!courseId) {
            navigate('/');
            return;
        }
        fetchVideos();
    }, [courseId]);

    useEffect(() => {
        if (videos?.length) {
            const fetchAllProgress = async () => {
                try {
                    const userId = getUserId();
                    
                    // First, get all video durations from the videos
                    const videoDurations = {};
                    videos.forEach(video => {
                        if (video.duration) {
                            videoDurations[video.id] = parseFloat(video.duration);
                        }
                    });

                    const response = await fetch(`${BASE_URL}/api/course-progress/${courseId}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    const results = await response.json();
                    
                    // Create a map of video progress
                    const progressMap = {};
                    results.forEach(result => {
                        const watched = parseFloat(result.watched_duration) || 0;
                        const duration = parseFloat(result.total_duration) || 0;
                        
                        if (duration > 0) {
                            progressMap[result.video_id] = Math.min(Math.round((watched / duration) * 100), 100);
                        }
                    });
                    
                    console.log('Progress Map:', progressMap);
                    setVideoProgress(progressMap);
                } catch (error) {
                    console.error('Error fetching video progress:', error);
                }
            };

            fetchAllProgress();
        }
    }, [videos, courseId, token]);

    useEffect(() => {
        if ((isSuperAdmin() || getRoleId() === 2) && courseId) {
            console.log('Fetching enrolled users for course:', courseId);
            fetchEnrolledUsers();
        }
    }, [courseId]);

    const fetchVideos = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/getlmsvideos?course_id=${courseId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });
            if (!response.ok) throw new Error('Failed to fetch videos');
            const data = await response.json();
            setVideos(data);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching videos:', error);
            setIsLoading(false);
        }
    };

    const handleVideoClick = (video) => {
        navigate(`/video-player`, {
            state: { 
                video,
                courseId,
                courseName,
                courseDescription: course?.course_description,
                videos
            }
        });
    };

    const fetchEnrolledUsers = async () => {
        try {
            // Get all users based on role
            let users;
            if (isSuperAdmin()) {
                // Super admin gets all users
                const usersResponse = await fetch(`${BASE_URL}/api/readadmins`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                users = await usersResponse.json();
            } else if (getRoleId() === 2) {
                // Admin gets only users from their client
                const usersResponse = await fetch(`${BASE_URL}/api/readadmins`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const allUsers = await usersResponse.json();
                // Filter users by client_id using the auth helper function
                const userClientId = getClientId(); // Import this from auth.js if not already imported
                users = allUsers.filter(user => user.client_id === userClientId);
            } else {
                return; // Regular users don't see this section
            }

            // Get progress for all relevant users
            const progressResponse = await fetch(`${BASE_URL}/api/course-client-progress/${courseId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const progressData = await progressResponse.json();

            // Create a progress map
            const progressMap = {};
            progressData.forEach(user => {
                progressMap[user.user_id] = user.progress;
            });

            // Combine user data with progress
            const enrichedUsers = users.map(user => ({
                ...user,
                progress: progressMap[user.user_id] || 0
            }));

            // Sort users by progress (descending) and then by name
            const sortedUsers = enrichedUsers.sort((a, b) => {
                if (b.progress !== a.progress) {
                    return b.progress - a.progress;
                }
                return `${a.first_name} ${a.last_name}`.localeCompare(`${b.first_name} ${b.last_name}`);
            });

            setEnrolledUsers(sortedUsers);
            setUserProgress(progressMap);

        } catch (error) {
            console.error('Error fetching enrolled users:', error);
        }
    };

    const EnrolledUsersSection = () => {
        if (!isSuperAdmin() && getRoleId() !== 2) return null;

        console.log('Current enrolled users:', enrolledUsers);
        console.log('Current user progress:', userProgress);

        return (
            <Paper sx={{ p: 3, mt: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Enrolled Users ({enrolledUsers.length})
                </Typography>
                {enrolledUsers.length === 0 ? (
                    <Typography variant="body2" color="text.secondary">
                        No users enrolled in this course yet.
                    </Typography>
                ) : (
                    <List sx={{ 
                        maxHeight: '400px',
                        overflow: 'auto',
                        msOverflowStyle: 'none',
                        scrollbarWidth: 'none',
                        '&::-webkit-scrollbar': {
                            display: 'none'
                        },
                        '& .MuiListItem-root': {
                            mb: 1.5
                        }
                    }}>
                        {enrolledUsers.map((user) => (
                            <ListItem
                                key={user.user_id}
                                sx={{
                                    borderRadius: 1,
                                    py: 1,
                                    px: 2,
                                    position: 'relative',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    backgroundColor: 'background.paper',
                                    '&:hover': {
                                        backgroundColor: 'action.hover',
                                    },
                                }}
                            >
                                <Box sx={{ 
                                    display: 'flex', 
                                    alignItems: 'center',
                                    flex: 1,
                                    minWidth: 0
                                }}>
                                    <Typography 
                                        variant="body2"
                                        sx={{ 
                                            fontWeight: 400,
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap'
                                        }}
                                    >
                                        {`${user.first_name} ${user.last_name}`}
                                        <Typography 
                                            component="span" 
                                            sx={{ 
                                                ml: 1,
                                                color: 'text.secondary',
                                                fontSize: '0.85em'
                                            }}
                                        >
                                            ({user.client_name})
                                        </Typography>
                                    </Typography>
                                </Box>
                                <Box sx={{ 
                                    display: 'flex',
                                    alignItems: 'center',
                                    ml: 2
                                }}>
                                    <Typography 
                                        variant="caption"
                                        sx={{ 
                                            color: theme => userProgress[user.user_id] > 0 ? 'primary.main' : 'text.secondary',
                                            opacity: 0.8,
                                            minWidth: '40px',
                                            textAlign: 'right'
                                        }}
                                    >
                                        {`${Math.round(userProgress[user.user_id] || 0)}%`}
                                    </Typography>
                                </Box>
                            </ListItem>
                        ))}
                    </List>
                )}
            </Paper>
        );
    };

    if (isLoading) {
        return <LinearProgress />;
    }

    return (
        <Box sx={{ 
            backgroundColor: 'background.default', 
            minHeight: '100vh',
            display: 'flex',
            px: { xs: 1, sm: 2, md: 3 },
            position: 'relative',
            width: '100%',
        }}>
            <CssBaseline />
            <Container 
                maxWidth="lg"
                sx={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    mt: 3,
                    mb: 4,
                    gap: 2,
                    px: { xs: 1, sm: 2, md: 3 }
                }}
            >
                <IconButton 
                    onClick={() => navigate('/')}
                    sx={{ 
                        alignSelf: 'flex-start',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        transition: 'all 0.2s ease',
                        borderRadius: '8px',
                        padding: '4px 12px',
                        '&:hover': {
                            backgroundColor: 'action.hover',
                        }
                    }}
                >
                    <ArrowBackIcon />
                    <Typography 
                        component="span"
                        sx={{ 
                            fontWeight: 500,
                            color: 'text.primary'
                        }}
                    >
                        Back to Courses
                    </Typography>
                </IconButton>

                <Box sx={{ 
                    display: 'flex', 
                    gap: 2,
                    width: '100%',
                }}>
                    <Box sx={{ 
                        width: { xs: '100%', md: '65%' },
                        maxWidth: '800px',
                        mr: { xs: 0, md: 2 },
                    }}>
                        <Paper sx={{ p: 0, mb: 3, overflow: 'hidden' }}>
                            {course?.image_path && (
                                <Box
                                    component="img"
                                    src={`${BASE_URL}/uploads${course.image_path}`}
                                    alt={course.course_name}
                                    onError={(e) => {
                                        e.target.style.display = 'none';
                                    }}
                                    sx={{
                                        width: '100%',
                                        height: 200,
                                        objectFit: 'cover'
                                    }}
                                />
                            )}
                            <Box sx={{ p: 3 }}>
                                <Typography variant="h4" gutterBottom>
                                    {course?.course_name}
                                </Typography>
                                <Typography variant="body1" sx={{ mb: 3 }}>
                                    {course?.course_description}
                                </Typography>
                            </Box>
                        </Paper>

                        <Paper sx={{ p: 3, mb: 3 }}>
                            <Typography variant="h6" gutterBottom>
                                Course Content
                            </Typography>
                            <List sx={{ 
                                maxHeight: '300px',
                                overflow: 'auto',
                                msOverflowStyle: 'none',
                                scrollbarWidth: 'none',
                                '&::-webkit-scrollbar': {
                                    display: 'none'
                                },
                                '& .MuiListItem-root': {
                                    mb: 1.5
                                }
                            }}>
                                {videos.map((video, index) => (
                                    <ListItem
                                        key={video.id}
                                        onClick={() => handleVideoClick(video)}
                                        sx={{
                                            borderRadius: 1,
                                            py: 1,
                                            px: 2,
                                            position: 'relative',
                                            cursor: 'pointer',
                                            backgroundColor: 'background.paper',
                                            '&:hover': {
                                                backgroundColor: 'action.hover',
                                            },
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            overflow: 'hidden',
                                            '&::before': {
                                                content: '""',
                                                position: 'absolute',
                                                left: 0,
                                                top: 0,
                                                height: '100%',
                                                width: `${videoProgress[video.id] || 0}%`,
                                                backgroundColor: 'rgba(200, 200, 200, 0.15)',
                                                transition: 'width 0.3s ease-in-out'
                                            }
                                        }}
                                    >
                                        <Box sx={{ 
                                            display: 'flex', 
                                            alignItems: 'center',
                                            flex: 1,
                                            minWidth: 0,
                                            position: 'relative',
                                            zIndex: 1
                                        }}>
                                            <Typography 
                                                variant="body2"
                                                sx={{ 
                                                    color: 'primary.main',
                                                    fontWeight: 500,
                                                    minWidth: '24px'
                                                }}
                                            >
                                                {`${index + 1}.`}
                                            </Typography>
                                            <Typography 
                                                variant="body2"
                                                sx={{ 
                                                    ml: 1,
                                                    fontWeight: 400,
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap'
                                                }}
                                            >
                                                {video.title}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ 
                                            display: 'flex',
                                            alignItems: 'center',
                                            ml: 2,
                                            mr: 1,
                                            position: 'relative',
                                            zIndex: 1
                                        }}>
                                            <Typography 
                                                variant="caption"
                                                sx={{ 
                                                    color: theme => videoProgress[video.id] > 0 ? 'primary.main' : 'text.secondary',
                                                    opacity: 0.8,
                                                    minWidth: '40px',
                                                    textAlign: 'right'
                                                }}
                                            >
                                                {`${videoProgress[video.id] || 0}%`}
                                            </Typography>
                                        </Box>
                                    </ListItem>
                                ))}
                            </List>
                        </Paper>

                        <EnrolledUsersSection />
                    </Box>

                    <Box sx={{ 
                        display: { xs: 'none', md: 'block' },
                        width: '35%',
                        maxWidth: '400px',
                        ml: { xs: 0, md: 2 },
                        position: 'sticky',
                        top: 24,
                        height: 'fit-content',
                        maxHeight: 'calc(100vh - 48px)',
                    }}>
                        <AIChat 
                            courseId={courseId}
                            courseName={courseName}
                            currentVideo={null}
                            videos={videos}
                        />
                    </Box>
                </Box>
            </Container>
        </Box>
    );
}

export default CourseDetails; 