import React from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import { useEffect, useState, useRef } from 'react';
import { BASE_URL } from '../Constants'; 
import backboardLogo from '../backboard.png';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';
import OrganizationRegistration from './OrganizationRegistration';

const theme = createTheme();

const loginUser = async (email, password, rememberMe, url, token = null) => {
  try {
    const payload = token ? { token } : { email, password };
    const response = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error);
    }

    const data = await response.json();

    // Clear existing storage
    ['token', 'userId', 'user', 'role_id', 'client_id'].forEach(key => {
      window.localStorage.removeItem(key);
      window.sessionStorage.removeItem(key);
    });

    const storage = rememberMe ? localStorage : sessionStorage;
    storage.setItem('token', data.token);
    storage.setItem('user', JSON.stringify(data.user));
    storage.setItem('role_id', data.user.role_id);
    storage.setItem('client_id', data.user.client_id);

    return data;
  } catch (error) {
    throw error;
  }
};

export default function LogIn() {
  const [ user, setUser ] = useState({}); 
  const [ googleLoaded, setGoogleLoaded] = useState(false);
  const [rememberMe, setRememberMe] = useState(true);
  const rememberMeRef = useRef(true);
  const [isEmailLinkSent, setIsEmailLinkSent] = useState(false);
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isRegistrationNeeded, setIsRegistrationNeeded] = useState(false);
  const [registrationData, setRegistrationData] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    rememberMeRef.current = rememberMe;
  }, [rememberMe]);

  async function handleCallbackResponse(response) {
    try {
      const result = await fetch(`${BASE_URL}/api/google_login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: response.credential })
      });

      const data = await result.json();

      if (result.status === 404 && data.requiresRegistration) {
        setRegistrationData({
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName
        });
        setIsRegistrationNeeded(true);
      } else if (!result.ok) {
        throw new Error(data.error || 'Login failed');
      } else {
        // Store all user data
        const storage = rememberMeRef.current ? localStorage : sessionStorage;
        storage.setItem('token', data.token);
        storage.setItem('user', JSON.stringify(data.user));
        storage.setItem('role_id', data.user.role_id);
        storage.setItem('client_id', data.user.client_id);
        
        // Force a page reload to ensure all permissions are properly initialized
        window.location.href = '/';
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error(error.message);
    }
  }  
  
  const initGoogle = () => {
    /* global google */
    google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: handleCallbackResponse,
      auto_select: false,
      cancel_on_tap_outside: true,
      prompt_parent_id: "signInGoogleDiv"
    });

    google.accounts.id.renderButton(
      document.getElementById("signInGoogleDiv"),
      { 
        theme: "outline", 
        size: "large", 
        width: "400",
        type: "standard",
        text: "continue_with",
        shape: "rectangular"
      }
    );
  };

useEffect(() => {
  if (document.querySelector('script[src="https://accounts.google.com/gsi/client"]')) {
    // Script already exists, just initialize
    initGoogle();
    setGoogleLoaded(true);
    return;
  }

  const script = document.createElement('script');
  script.src = "https://accounts.google.com/gsi/client";
  script.async = true;
  script.onload = () => {
    initGoogle();
    setGoogleLoaded(true);
  };
  document.body.appendChild(script);

  return () => {
    const existingScript = document.querySelector('script[src="https://accounts.google.com/gsi/client"]');
    if (existingScript) {
      document.body.removeChild(existingScript);
    }
  };
}, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
  
    const email = data.get('email');
    const password = data.get('password');
  
    try {
      await loginUser(email, password, rememberMe, `${BASE_URL}/api/login`);
  
      var storage = rememberMe ? localStorage : sessionStorage;
      var decodedToken = jwt_decode(storage.getItem('token'));
  
      if (decodedToken.farm_id) {
        navigate('/');
      } else {
        navigate('/selectfarm');
      } 
    } catch (error) {
      console.error('Error:', error.message);
    }
  };  

  const handleEmailLink = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    
    try {
      const response = await fetch(`${BASE_URL}/api/email-link-login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
      });

      const data = await response.json();

      if (response.status === 404 && data.requiresRegistration) {
        // Store registration data and show registration form
        setRegistrationData({ email });
        setIsRegistrationNeeded(true);
      } else if (!response.ok) {
        throw new Error(data.error || 'Failed to send login link');
      } else {
        setIsEmailLinkSent(true);
        toast.success('Login link sent to your email');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (isRegistrationNeeded) {
    return <OrganizationRegistration data={registrationData} />;
  }

  return (
    <Box
      sx={{
        backgroundColor: '#1b1b1b',
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      }}
    >
      <Box sx={{ position: 'absolute', top: 20, left: 20 }}>
        <img 
          src={backboardLogo} 
          alt="BackBoard Logo" 
          style={{ width: '40px', height: 'auto' }}
        />
      </Box>

      <Container component="main" maxWidth="sm" sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <Box sx={{
          width: '100%',
          maxWidth: '400px',
          textAlign: 'center',
        }}>
          <Typography component="h1" variant="h3" sx={{ 
            mb: 2, 
            color: '#FFFFFF',
            fontWeight: 600
          }}>
            Welcome to BackBoard
          </Typography>

          <Typography variant="h6" sx={{ 
            mb: 4, 
            color: '#B0B0B0',
            fontWeight: 400
          }}>
            Enter your email to sign in to your account
          </Typography>

          <Box component="form" onSubmit={handleEmailLink} noValidate>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              placeholder="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={isEmailLinkSent}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'rgba(255, 255, 255, 0.05)',
                  borderRadius: '8px',
                  height: '48px',
                  '& fieldset': {
                    borderColor: 'transparent',
                  },
                  '&:hover fieldset': {
                    borderColor: 'transparent',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'transparent',
                  }
                },
                '& .MuiInputBase-input': {
                  color: '#FFFFFF',
                }
              }}
            />
            
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ 
                mt: 2, 
                py: 1.5,
                backgroundColor: '#7B4FFF',
                height: '48px',
                color: '#FFFFFF',
                borderRadius: '8px',
                textTransform: 'none',
                fontSize: '1rem',
                '&:hover': {
                  backgroundColor: '#6B3FEF',
                }
              }}
              disabled={isEmailLinkSent || isLoading}
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : isEmailLinkSent ? (
                'Check Your Email'
              ) : (
                'Sign In with Email'
              )}
            </Button>

            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center',
              my: 3
            }}>
              <Box sx={{ flex: 1, height: '1px', backgroundColor: 'rgba(255, 255, 255, 0.1)' }} />
              <Typography variant="body2" sx={{ color: '#B0B0B0', mx: 2 }}>OR</Typography>
              <Box sx={{ flex: 1, height: '1px', backgroundColor: 'rgba(255, 255, 255, 0.1)' }} />
            </Box>

            <div 
              style={{ 
                display: 'flex', 
                justifyContent: 'center',
                width: '100%'
              }}
            >
              <div 
                id="signInGoogleDiv"
                style={{
                  width: '100%',
                  height: '48px',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              ></div>
            </div>
          </Box>
        </Box>
      </Container>
    </Box>
  );
} 